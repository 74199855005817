
import React, { useState, useRef, useEffect, Suspense, lazy } from 'react';
import { useLocation, Link } from "react-router-dom";
import {
    Box,
    Typography,
    Button,
    Grid,
    Card,
    useMediaQuery,
    Dialog,
    DialogTitle,
    IconButton,
    CardContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import coursebusiness from '../assets/coursemain.jpg';
import coursefeature from '../assets/coursefeature.jpg';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert1.webp'; // Ensure this path is correct
import AlertBox from "./AlertBox"; // Adjust the import path as needed
// Import slick-carousel CSS if used in PromotionalCard or other components
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyLoadComponent from '../Homepage/LazyLoadedComponent';
import courses from './CourseData';

// Lazy loaded components using React.lazy
const PromotionalCard = lazy(() => import('./CourseFeature'));
const BusinessIndividualSection = lazy(() => import('./BusinessIndividualSection'));
const FAQSection = lazy(() => import('../Experts/FaqSection'));
const Testimonials = lazy(() => import('../Homepage/Testimonials'));
const BrowseAllCourses = lazy(() => import('./AllCourseCard'));
const CourseRegister = lazy(() => import('./CourseRegister'));
const TrendingBlogs = lazy(() => import('../Experts/TrendingBlogs')); // If applicable


// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
    filterChipsContainer: {
        gap: 0,
    },
    bannerImageContainer: {
        top: '50px !important',
    },
    newInfoCard: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        background: 'linear-gradient(to bottom, #dcdcdc, #e3e3e3)',
    },
    h5fontPara: {
        fontWeight: 500,
        // Add other styles as needed
    },
    dialogTitle: {
        padding: '0px 0px !important',
    },
    h5: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    dialogContent: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
        // Prevent horizontal overflow
        overflowX: 'hidden',
    },
    // Add other custom styles as needed
}));

// Banner Component
const Banner = () => {
    const theme = useTheme();
    const classes = useGlobalStyles();
    const styleClasses = useStyles();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isIpad = useMediaQuery('(min-width:768px) and (max-width:1024px)'); // Detect iPad screen sizes

    return (
        <Box className={classes.bannerContainer}>
            <Box className={classes.bannerTextContainer}>
                <Typography
                    variant="h1"
                    className={classes.h1banner}
                    gutterBottom
                >
                    Growth Academy
                </Typography>
                <Typography
                    variant="h3"
                    className={classes.h3font}
                >
                    Access Expert-led <span className={classes.span}>Online Courses</span> for Individuals and Businesses
                </Typography>
                <Typography variant="h6" className={`${classes.h6banner} ${classes.h6HeadingDesc}`} width={'65%'}>
                    Whether you're a business or an individual, Growth Academy’s
                    online courses are built to equip you with the skills needed to
                    overcome challenges and succeed in any industry.
                </Typography>
                <Box className={`${classes.filterChipsContainer} ${styleClasses.filterChipsContainer}`}>
                    <Button
                        variant="outlined"
                        component={Link}
                        to="/business-course"
                        className={classes.businessCourseButton}
                    >
                        For Business
                    </Button>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/individual-course"
                        className={classes.individualCourseButton}
                    >
                        For Individuals
                    </Button>
                </Box>
            </Box>
            <Box className={`${classes.bannerImageContainer} ${styleClasses.bannerImageContainer}`}>
                <img
                    src={coursebusiness}
                    alt="Banner Image"
                    className={classes.bannerImage}
                    loading="lazy" // Native lazy loading
                />
            </Box>
        </Box>
    );
};

// CourseFeatures Component
const CourseFeatures = () => {
    const classes = useGlobalStyles();
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const features = [
        {
            title: 'Social and Collaborative Learning',
            description:
                'Social and collaborative learning experiences crafted to ensure retention and absorption of concepts and the ability to apply them for life.',
        },
        {
            title: 'Immersive Live Activities',
            description:
                'Group and individual activities that involve you, ensuring a sound grasp of the subject matter being covered.',
        },
        {
            title: 'Instructional Benefits',
            description:
                'High standards of instructional design guaranteed to impart knowledge seamlessly and motivate you.',
        },
        {
            title: 'Blog-like Lecture Notes',
            description:
                'Churn through the course material with considerable ease as our lecture notes emulate blogs, with infographics and videos to enhance your learning experience.',
        },
    ];

    return (
        <Box className={classes.courseFeaturesContainer}>
            <Typography variant="h4" className={classes.h4font} align='left'>
                Course Features
            </Typography>
            <Grid container spacing={4} alignItems="stretch" className={classes.mainGrid}>
                {/* Left Side: Heading and Image */}
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Box style={{ marginBottom: 16 }}>
                        <Typography variant="h6" className={classes.description}>
                            Learn from industry experts, engage in hands-on projects, and enjoy a learning journey that's as flexible as it is impactful.
                        </Typography>
                    </Box>
                    <Box className={classes.courseFeatureImgBox}>
                        <img
                            src={coursefeature}
                            alt="Student learning"
                            className={classes.courseFeatureImg}
                            loading="lazy" // Native lazy loading
                        />
                    </Box>
                </Grid>

                {/* Right Side: Feature Cards */}
                <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} style={{ flexGrow: 1 }}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} key={index} style={{ flexGrow: 1 }}>
                                <Card className={classes.featureCard}>
                                    <CardContent style={{ padding: 0, flexGrow: 1 }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 8, width: 'fit-content' }}>
                                            {/* Custom Arrow */}
                                            <Box className={classes.customArrow}>
                                                &gt; {/* ">" symbol */}
                                            </Box>
                                            <Typography
                                                variant="h5"
                                                className={classes.h5font}
                                            >
                                                {feature.title}
                                            </Typography>
                                        </Box>
                                        <Typography variant="subtitle1" className={classes.subtitlefont}>
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

// Main AllCourses Component
const AllCourses = () => {
    useEffect(() => {
        const mainTitle = 'Best Online Courses | OpenGrowth.AI | Growth Academy';
        const subtitle = 'Discover the best online courses at OpenGrowth.AI Growth Academy and elevate your career with expert-led, practical learning programs.';
        document.title = `${mainTitle} - ${subtitle}`;
      }, []);
    const classes = useGlobalStyles();
    const styleClasses = useStyles();
    const theme = useTheme();
    const location = useLocation();

    const [showPopup, setShowPopup] = useState(false);
    const [popupClosed, setPopupClosed] = useState(false);

    // AlertBox state
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');

    useEffect(() => {
        const formSubmitted = localStorage.getItem('courseRegisterSubmitted');
        if (!formSubmitted && !popupClosed) {
            const timer = setTimeout(() => {
                setShowPopup(true);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [popupClosed]);

    // Function to handle popup closure
    const handleClosePopup = () => {
        setShowPopup(false);
        setPopupClosed(true);
    };

    const handleSubmitSuccess = (message, type) => {
        setAlertMessage(message);
        setAlertType(type);
        setAlertOpen(true);
    };

    const handleSubmitError = (message, type) => {
        setAlertMessage(message);
        setAlertType(type);
        setAlertOpen(true);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    // Callback function to handle enrollment success
    const handleEnrollmentSuccess = () => {
        setAlertMessage(" You’re all set! Welcome to a world of learning with our expertly curated courses. Our Team will review your details and get back to you shortly!");
        setAlertType("success"); // 'success' is informational; styling is neutral
        setAlertOpen(true);
        handleClosePopup();

        // Set the localStorage flag to prevent popup on reload
        localStorage.setItem('courseRegisterSubmitted', 'true');
    };

    return (
        <Box style={{ padding: 8, paddingTop: 0 }}>
            {/* Header Component (if any) */}
            {/* <Header /> */}
            <Suspense fallback={<div>Loading Banner...</div>}>
                <Banner />
            </Suspense>

            <Suspense fallback={<div>Loading Course Features...</div>}>
                <CourseFeatures />
            </Suspense>

            {/* New Information Card with Bold Paragraph */}
            <Suspense fallback={<div>Loading Information...</div>}>
                <Card className={classes.newInfoCard}>
                    <Typography variant="h5" className={classes.h5fontPara}>
                        At Growth Academy, we help businesses across industries, including AI startups, punch above
                        their weight with actionable, expert-led online courses. Gain deep, specialized skills while
                        expanding your knowledge across multiple domains with access to our diverse courses
                    </Typography>
                </Card>
            </Suspense>

            {/* Browse All Courses Section - Lazy Loaded */}
            <Suspense fallback={<div>Loading Courses...</div>}>
                <LazyLoadComponent>
                    <BrowseAllCourses courses={courses} />
                </LazyLoadComponent>
            </Suspense>

            {/* Promotional Card - Lazy Loaded */}
            <Suspense fallback={<div>Loading Promotional Content...</div>}>
                <LazyLoadComponent>
                    <PromotionalCard />
                </LazyLoadComponent>
            </Suspense>

            {/* Business and Individual Section - Lazy Loaded */}
            <Suspense fallback={<div>Loading Sections...</div>}>
                <LazyLoadComponent>
                    <BusinessIndividualSection />
                </LazyLoadComponent>
            </Suspense>

            {/* FAQ Section - Lazy Loaded */}
            <Suspense fallback={<div>Loading FAQs...</div>}>
                <LazyLoadComponent>
                    <FAQSection />
                </LazyLoadComponent>
            </Suspense>

            {/* AlertBox for general messages */}
            {alertOpen &&
                <AlertBox
                    open={alertOpen}
                    onClose={handleAlertClose}
                    msg={alertMessage}
                    msg_type={alertType} // Currently not used for styling
                    context="course"
                />
            }

            {/* Enrollment Dialog - Lazy Loaded */}
            <Suspense fallback={<div>Loading Registration...</div>}>
                <Dialog
                    open={showPopup}
                    onClose={handleClosePopup}
                    maxWidth={'sm'}
                    fullWidth
                    classes={{ paper: styleClasses.dialogContent }}
                >
                    <DialogTitle disableTypography className={styleClasses.dialogTitle}>
                        <Typography variant="h5" className={`${classes.h5fontPara} ${styleClasses.h5}`} align="center" gutterBottom>
                            Learn with us
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={handleClosePopup}
                            style={{ position: 'absolute', right: 8, top: 8, marginBottom: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <CourseRegister
                        onSubmitSuccess={handleEnrollmentSuccess}
                        onSubmitError={handleSubmitError}
                        onClose={handleClosePopup}
                    />
                </Dialog>
            </Suspense>

            {/* Footer Component (if any) */}
            {/* <Footer /> */}
        </Box>
    );
};

export default AllCourses;
