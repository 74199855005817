// RegistrationDetails.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import Page1 from './Page1';
import Page2 from './Page2';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { generateID, api } from '../../../Helper';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';

// Define custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#ffffff',
    minHeight: '100vh',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center content horizontally
    position: 'relative',
  },
  alertBox: {
    width: '100%',
    maxWidth: '600px', // Limit max width for better readability
    marginBottom: theme.spacing(2), // Space below the alert
  },
  contentBox: {
    width: '100%',
    flexGrow: 1, // Allow content to grow and push alerts to the top
  },
}));

const RegistrationDetails = () => {
  const classes = useStyles();

  const [step, setStep] = useState(1);

  // Instead of userType, we refer to "signingAs" throughout
  const [signingAs, setSigningAs] = useState('');

  // Data from Page2
  const [page2Data, setPage2Data] = useState({ courses: [], experts: [] });

  // For showing success or error messages inline
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();
  const location = useLocation();

  // Form data passed from previous pages
  const formData = location.state?.formData || {};

  // Auto-dismiss alerts after 5 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000); // 5 seconds
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 5000); // 5 seconds
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleNext = () => {
    // Move to the next step or register user if on the last step
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      registerUser();
    }
  };

  const handleBack = () => {
    // Go back to the previous step if available
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSkipAll = () => {
    // If user wants to skip everything, just register immediately
    registerUser();
  };

  const handleDataChange = (value) => {
    if (step === 1) {
      // On Page1, the data is "signingAs"
      setSigningAs(value);
    } else if (step === 2) {
      // On Page2, the data includes { courses, experts }
      setPage2Data(value);
    }
  };

  /**
   * registerUser
   *  - Calls the backend to register the user
   *  - On success, shows a success alert and redirects
   *  - On failure, shows an error alert
   */
  const registerUser = () => {
    // Clear out any previous messages
    setSuccessMessage('');
    setErrorMessage('');

    const user_view = 'academy'; // or 'webinar', depending on your logic

    // Handle user image if needed
    const img = formData.img;
    let param_img = formData.img;
    if (user_view === 'webinar' && ![null, '', undefined].includes(param_img)) {
      param_img = img;
    }

    // Build the payload for the API request
    const userData = {
      user_view,
      about: formData.about,
      img: param_img,
      fname: formData.fname,
      lname: formData.lname,
      email: formData.email,
      password: formData.password,
      dob: formData.dob,
      gender: formData.gender,
      interest: formData.interest_values,
      industry: formData.inds,
      edu: formData.edu,
      employer: formData.emp,
      country: formData.country,
      type: 'student',
      status: 'student',
      ip: formData.ip_address,
      os: `${osName} ${osVersion}`, // Corrected template literal
      browser: `${browserName} ${browserVersion}`, // Corrected template literal
      signing_as: signingAs,
      courses: page2Data.courses,
      experts: page2Data.experts,
    };

    // Save signingAs to localStorage if needed
    localStorage.setItem('signingAs', signingAs);

    // Make the API call
    axios
      .post(`${api}/addUser`, userData) // Corrected template literal
      .then((response) => {
        if (response.data.msg === 'student') {
          // On success, store user info in localStorage
          const storedUserData = {
            ...userData,
            id: response.data.id.toString(),
            password: '',
            user_view: 'academy',
            interest: formData.interest_values?.toString() || '',
          };

          localStorage.setItem('ac_login_user', JSON.stringify(storedUserData));

          const p_info = [
            { profile_mail: formData.email },
            { profile_type: 'student' },
            { profile_id: response.data.id.toString() },
          ];
          localStorage.setItem('profile_info', JSON.stringify(p_info));

          localStorage.setItem('login_token', generateID());

          // Show success alert on the same page
          setSuccessMessage(`You are successfully registered as ${signingAs}!`); // Corrected template literal
          console.log(signingAs);
          

          // Redirect to the appropriate dashboard after 2 seconds
          setTimeout(() => {
            let dashboardUrl = '/dashboard'; // default
            if (signingAs === 'learner') {
              dashboardUrl = '/dashboard-courses';
            } else if (signingAs === 'entrepreneur') {
              dashboardUrl = '/dashboard-entrepreneur';
            } else if (signingAs === 'expert') {
              dashboardUrl = '/dashboard-expert';
            }
            window.location.assign(dashboardUrl);
          }, 2000);
        } else {
          // If the backend responded with something unexpected
          setErrorMessage('Something went wrong during registration.');
        }
      })
      .catch((error) => {
        console.error('Registration error:', error);
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage('Registration failed. Please try again.');
        }
      });
  };

  return (
    <Box className={classes.container}>
      {/* SUCCESS MESSAGE */}
      <Collapse in={!!successMessage}>
        {successMessage && (
          <Box className={classes.alertBox}>
            <Alert severity="success" variant="filled">
              {successMessage}
            </Alert>
          </Box>
        )}
      </Collapse>

      {/* ERROR MESSAGE */}
      <Collapse in={!!errorMessage}>
        {errorMessage && (
          <Box className={classes.alertBox}>
            <Alert severity="error" variant="filled">
              {errorMessage}
            </Alert>
          </Box>
        )}
      </Collapse>

      {/* CONTENT */}
      <Box className={classes.contentBox}>
        {/* STEP 1 */}
        {step === 1 && (
          <Page1
            data={signingAs}
            onDataChange={handleDataChange}
            onNext={handleNext}
            onSkip={handleSkipAll}
            showBackButton={false}
          />
        )}

        {/* STEP 2 */}
        {step === 2 && (
          <Page2
            data={page2Data}
            onDataChange={handleDataChange}
            onNext={handleNext}
            onBack={handleBack}
            onSkip={handleSkipAll}
            showBackButton={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default RegistrationDetails;
