import axios from 'axios'
import CryptoJS from "crypto-js";
import data from '../../translation/Academy.json';
import {getCacheDetails} from "./caching";
import AlertBox from "./AlertBox";
//import config from '../../translation/config.json';


import {
  FaSeedling,
  FaUserCircle,
  FaAddressCard,
  FaHireAHelper,
  FaUsers,
  FaVolleyballBall,
  FaShoppingCart,
  FaSignInAlt,
  FaListAlt,
  FaMailBulk,
  FaBook,
  FaStaylinked,
  FaBookReader,
  FaSlack ,
  FaGem,
  FaDiceD6,
  FaCircle,
  FaUserFriends,
  FaHeart,
  FaMoneyCheck,
  FaRegComments,
  FaRegCommentDots,
  FaPhotoVideo,
} from "react-icons/fa";
import { HiUserGroup } from 'react-icons/hi';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import { SiDiscord } from 'react-icons/si';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';


export let is_local=window.location.hostname=="localhost"?true:false
export let config = "";
try {
  config = require("../../translation/config.json");
} catch (ex) {
	try 
	{
	  config = require("./translation/config.json");
	} catch (err) {
	  console.error("Failed to load config file:", err);
	}
}
////console.log(config);
export let mentor_nested= config.mentorship.value && config.mentorship.levels;
export let course_nested =  config['course']['levels'];
export let webinar_nested = config['webinar']['levels'];
export let blog_nested = config['blog']['levels'];
export let quiz_nested = config['quiz']['levels'];
export let admin_nested = config['admin']['levels'];
export let community_nested = config['community']['levels'];
export let header_nested = config['header'];
let end_point = 'http://'+window.location.hostname+':5000/';
let origin = "http://localhost:3000/";
let platform="Mounted on localhost"
if(is_local==false)
{
	origin="*"
	end_point=""
	platform="Mounted on server"
}
export let socket_setup={"origin":origin,"end_point":end_point,"platform":platform}
console.log(socket_setup)

export let images={
	"webinar-banner":window.location.origin+"/assets/images/web/"+"webinar-banner.jpeg",
	"user-blank":window.location.origin+"/assets/images/web/"+"blank.webp",
	"check":window.location.origin+"/assets/images/web/"+"check.png",
	"upload":window.location.origin+"/assets/images/web/"+"upload.jpg",
	"message":window.location.origin+"/assets/images/web/"+"message.png",
	"sm_img":window.location.origin+"/assets/images/web/"+"sq-blank.jpg",
	"rect-blank":window.location.origin+"/assets/images/web/"+"rect-blank.jpg",
	"mid_img":window.location.origin+"/assets/images/web/"+"lg-blank.png"
};
//alert(window.location.origin);
////console.log(images)
export let og_path="/";
export let location=window.location.pathname
export let admin_path=['/growth-admin','/schedule-webinar','/update-speakers','/view-users','/webinar-registration','/view-attendees','/manage-settings','/webinar-visitors','/admin-logs','/view-master-user','/get-images','/logs','/upload-og-images','/academy-users','/course/view','/mentors-and-experts'] 
export let adminPage=admin_path.includes(location)?true:location.startsWith('/course/update')==true?true:location.startsWith('/university')==true?true:location.startsWith('/coupon')==true?true:location.startsWith('/subscription')==true?true:location.endsWith('module')==true?true:false
//export let projects = [{ key: 'academy', title: 'Academy' },{ key: 'cimp', title: 'CIMP' }];
export let projects = [{ key: window.location.hostname, title: window.location.hostname }];

export let freshPages=["/manage-modules","/test","/googletest"]	
export let onlyProfessor=["/add","/update","/view"]	
export let acUser=["/dashboard","/profile","/chatbox"]
export let onlyStudent=["/mycourses","/bookmarked","/recently-viewed","/enrolledcourses","/timeline","/start","/progress","/orders"]	
export let onlyOutside=["/","/login","/webinars",'/webinar-description','/webinars','/workshop-login','/webinar-register','/webinar-password','/webinar-user','/homepage','/test','/homepage-old','/register/user','/register/expert','/forgotpassword','test','/about-us','/womens-day','/events','/privacy-policy','/growth-admin',,'allcourses'] 

//export let outside_pages=onlyOutside.includes(window.location.pathname)?true:(window.location.pathname).startsWith('/profile')==true?true:(window.location.pathname).startsWith('/webinar-description')==true?true:(window.location.pathname).startsWith('/courses/')==true?true:(window.location.pathname).startsWith('/webinars/')==true?true:(window.location.pathname).startsWith('/webinar-register/')==true?true:window.location.pathname=='/og-home'?true:false
//export let outside_pages=onlyOutside.includes(window.location.pathname)?true:(window.location.pathname).startsWith('/webinar-description')==true?true:(window.location.pathname).startsWith('/courses/')==true?true:(window.location.pathname).startsWith('/webinars/')==true?true:(window.location.pathname).startsWith('/webinar-register/')==true?true:window.location.pathname=='/og-home'?true:false
export let outside_pages=onlyOutside.includes(window.location.pathname)?true:(window.location.pathname).startsWith('/webinar-description')==true?true:(window.location.pathname).startsWith('/webinars/')==true?true:(window.location.pathname).startsWith('/webinar-register/')==true?true:window.location.pathname=='/og-home'?true:false

export let user_view_arr=["visitor","admin","webinar","academy"]
export let user_view="visitor"
if([null].includes(localStorage.getItem('login_token'))==false)
{
	if(["admin"].includes(localStorage.getItem('login_token')))
	{
		user_view="admin"
	}
	else if(["webinar"].includes(localStorage.getItem('login_token')))
	{
		user_view="webinar"
	}
	else
	{
		user_view="academy"
	}
}


export const email_list=['anshurk1@gmail.com','dasAnita47@gmail.com','anil11912@gmail.com','anamika261994@gmail.com','reshmadas11912@gmail.com']

export let inactivity_duration=1000*5*60 
export let register=location.startsWith('/register')==true? true:false
//export let barColor='#f9fafc';
export let barColor='#eef0f51f';
export let redColor='#fb4c2f';
export let lightBlueColor='#22add7';
export let secondaryColor='#f9bb02';
export let pinkColor='#e75480';
export let primaryColor='#25387C';
export let successColor='#4caf50';
export let notifyColor='#EA4335';

// aug-feb export let innerBodyColor="#fbfbfb";
// march export let innerBodyColor="#ffffff";
export let innerBodyColor="#F3F6F9";

export let api="/api";
export let path="/assets/images/courses/";
export let transcript_path= '../public/assets/images/chapters/transcript/'
export let quiz_path="/assets/images/courses/quiz/";
export let university_path="/assets/images/university/";
export let videopath="/assets/images/courses/videos/";
export let chapter_path="/assets/images/chapters/";
export let userProfile_path="/assets/images/users/";
export let userTimeline_path="/assets/images/users/timeline/";
export let webinar_img_path="/assets/images/webinar/";
export let speakers_path="/assets/images/speakers/";
export let community_path="/assets/images/community/";
export let logo_path="/assets/images/logo/";
export let web_path="/assets/images/web/";
export let json_path="/public/";

export const studentList = ['fname', 'lname', 'email','password','gender','interest_values','edu','country','about','dob'];
export const professorList = ['fname', 'lname', 'email','password','interest_values','country'];
export const courseList = ['course_name', 'course_de', 'course_university','course_edu','course_edu','course_inds','course_skill','course_category','course_level','course_duration','course_tags_values','course_instructor_values'];

export const folder_list=[
'UPLOAD_FOLDER',
'THUMB_UPLOAD_FOLDER',
'TRANSCRIPT_FOLDER',
'UNIVERSITY_IMG_FOLDER',
'SPEAKER_IMG_FOLDER',
'POPUP_IMG_FOLDER',
'COURSE_IMG_FOLDER',
'COURSE_VIDEO_FOLDER',
'CHAPTER_FOLDER',
'QUIZ_IMG_FOLDER',
'COURSE_THUMB_FOLDER',
'STREAM_FOLDER',
'TIMELINE_UPLOAD_FOLDER',
'WEBINAR_IMG_FOLDER',
'LINKEDIN_IMG_FOLDER',
'FACEBOOK_IMG_FOLDER',
'GOOGLE_IMG_FOLDER',
'LOGO_FOLDER',
'WEB_FOLDER']

export const chapterList = ['chapter_name', 'chapter_de']
  export function getText(name){
	let field='';
	   switch (name) {
      case "fname" :
		field="First name";break;
	   case "lname":
		field="Last name";break;
	  case "gender":
		field="Gender";break;
	  case "email":
		field="Vaild email address";break;
	  case "password":
		field="Password";break;
      case "dob":
        field="DOB";break;
      case "gender":
        field="Gender";break;
      case "inte":
		field="Interest";break;
	  case "interest_values"  :
		field="Interest";break;
	  case "clg":
		field="College";break; 
	  case "other_college":
		field="Educational institute";break; 
	  case "inds":
		field="Industry";break;
      case "edu":
        field="Qualification";break;
	 case "emp":
        field="Employer";break;
     case "exp":
        field="Experience";break;
      case "country":
        field="Country";break;
	 case 'about':
        field="About details";break;
      default:
        break;
    }
	return field;
}; 
export const card_list=["rgb(40 72 187)","#f9bb02","#4CAF50"];
export const color_list=["#098024","#4CAF50","#2D96F3","#FFC200","#F44336","text-secondary","#098024","#4CAF50"];
export const color_cls=["text-success bg-success","text-primary bg-primary","text-warning bg-warning","text-danger bg-danger","text-info bg-info","text-success bg-success"];
export const Skill = ['Programming','Marketing','Digital solutions','Accountancy'];
export const Level = ['Beginner','Intermediate','Masters'];
export const Duration = ['1 Week','2 Week','1 Month','3 Month','6 Month','1 Year','2 Year'];
//export const Category = ['Leadership','Strategy','Mentorship','Innovation','Finance'];
export const Category =['Product', 'Sales', 'Marketing', 'Finance', 'HR', 'Tech', 'Communication', 'Leadership'];
export const CourseTag = ['Technology','Marketing','Entrepreneurship','Innovation','Finance'];

export const edu_list=['No formal education','Primary education','Secondary education or high school'
,'Intermediate','GED','Vocational qualification',"Bachelor's degree","Master's degree",'Doctorate or higher'];

export const clg_list=
['Amity University', 
 'Anna University Chennai', 
 'Delhi Technological University', 
 'Doon University', 
 'Harvard University', 
 'Johns Hopkins University', 
 'Massachusetts Institute of Technology', 
 'University of California', 
 'University of Delhi', 
 'University of Illinois', 
 'University of Oxford', 
 'University of Washington',
 'Others'
 ];
export const employer_list=['OpenGrowth Academy','Infosys','Wipro','HCL Technologies','Tech Mahindra',
'Oracle Financial Services Software','Larsen & Toubro Infotech','Mphasis','Mindtree','Cognizant'];
export let extension_list=["PPT's",'PDF','Excel File','Link','Any File Format'];
export const country_list = [
	'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Anguilla', 'Antigua and Barbuda',
	'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain',
	'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan',
	'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria',
	'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 'Central African Republic',
	'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo', 'Costa Rica', 'Croatia', 'Cuba',
	'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic',
	'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia',
	'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany',
	'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti',
	'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel',
	'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait',
	'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein',
	'Lithuania', 'Luxembourg', 'Madagascar', 'Malaysia', 'Malawi', 'Maldives', 'Mali', 'Malta',
	'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco',
	'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal',
	'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia',
	'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine', 'Panama', 'Papua New Guinea', 'Paraguay',
	'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda',
	'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa',
	'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles',
	'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa',
	'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden',
	'Switzerland', 'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo', 'Tonga',
	'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda',
	'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan',
	'Vanuatu', 'Vatican City', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
  ];
  
export let mentors_category = ["Fractional","Demand Engagement","Artificial Intelligence", "Data Science", "Entrepreneur", "Expert", "Finance", "Human Resource", "Leadership", "Marketing", "Seasoned Entrepreneur"];
export let interest_list = [
    "AI and Emerging Technologies",
    "Business Development",
    "Competitor Analysis",
    "Content Marketing",
    "Customer Acquisition and Retention",
    "Data Analytics and Insights",
    "Digital Marketing",
    "E-commerce",
    "Entrepreneurship",
    "Financial Planning and Budgeting",
    "Fractional Expert",
    "Funding and Investment Opportunities",
    "Fundraising Strategies",
    "Green Tech/Sustainability",
    "Growth Expert",
    "Growth Assistant",
    "Human Resources",
    "Idea Validation",
    "Launching a Startup",
    "Learning Resources",
    "Legal and Compliance Support",
    "Marketing and Branding",
    "MVP Development",
    "Negotiation",
    "Networking and Partnerships",
    "Operational Efficiency",
    "Personal Branding",
    "Pitch Deck Expert",
    "Product Management",
    "Sales and Revenue Growth",
    "Scaling and Growth Strategies",
    "Team Building and Hiring",
    "Venture Assistance",
    "Venture Capital"
];
export let ind_list = ["Accounting", "Airlines/Aviation", "Animation", "Apparel/Fashion", "Architecture/Planning", "Arts/Crafts", "Automotive", "Aviation/Aerospace", "Banking/Mortgage", "Biotechnology/Greentech", "Chemicals", "Construction", "Cosmetics", "Dairy", "Defense/Space", "Design", "E-Learning", "Farming", "Fishery", "Food/Beverages", "Fundraising", "Furniture", "Gambling/Casinos", "Glass/Ceramics/Concrete", "Health/Fitness", "Hospitality", "Import/Export", "Insurance", "Internet", "Judiciary", "Legal", "Leisure/Travel", "Library", "Logistics/Procurement", "Machinery", "Maritime", "Marketing/Advertising/Sales", "Mining/Metals", "Museums/Institutions", "MVP (Minimal Valuable Product)", "Music", "Nanotechnology", "Newspapers/Journalism", "Non-Profit/Volunteering", "Oil/Energy/Solar/Greentech", "Outsourcing/Offshoring", "Packaging/Containers", "Pharmaceuticals", "Philanthropy", "Photography", "Plastics", "Printing", "Ranching", "Renewables/Environment", "Restaurants", "Security/Investigations", "Semiconductors", "Shipbuilding", "Sports", "Staffing/Recruiting", "Supermarkets", "Telecommunications", "Textiles", "Translation/Localization", "Transportation", "Utilities", "Venture Capital", "Veterinary", "Warehousing", "Wholesale", "Wine/Spirits", "Wireless", "Writing/Editing"];
ind_list = [...new Set([...mentors_category, ...ind_list])].sort();
export let course_list = ["Digital Marketing", "Prospective Fractional Experts Guide", "How to Measure Product Market Fit", "AI Basics", "Identifying Your Target Audience", "SEO Basics", "MVP Fundamentals", "Market Analysis Basics", "Public Speaking", "Make Your Pitch Investor Ready", "Startup Fundamentals", "Competitor Analysis", "How to Plan an MVP", "HR for Remote Teams (HRD & HRM)", "How to Incorporate a Company", "How to Pitch Your Startup", "Business Modeling through Strategy and Analysis", "Founder and Co-Founder Dynamics", "Content Marketing Essentials"]
interest_list = [...new Set([...mentors_category, ...interest_list])].sort();
export const exp_list = [
  'C-Suite',
  'Executive',
  'Executive - Vice President',
  'Founder',
  'Intern / Trainee',
  'Junior / Entry-level (0-2 years experience)',
  'Lead / Manager',
  'Mid-level (2+ years experience)',
  'Not Applicable',
  'Senior Manager / Director'
];


export let user_id=null;
export let user_status_id=null

if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	user_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	user_status_id=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}	

let ButtonText =''
if(window.location.pathname==="/editprofile")
{
	ButtonText="Update";
}
else
{
	ButtonText="Register";
}
export const setButtonText=ButtonText;
 export function setVariables(arr)
{
	if(window.location.pathname==="/editprofile")
    {
		if(arr==='inte')
		{
			let newArr=localStorage.getItem(arr);
			return newArr.split(',');
		}
		else
		{
			return localStorage.getItem(arr);

		}
    }	
    else
    {
		if(arr==='inte')
		{
			return [];
		}
		else{
			return null;
		}
        
    }	 
}
 export function setCVariables(arr)
{
   // alert(window.location.pathname)
	if(window.location.pathname==="/addcourse" || window.location.pathname==="/chapters/add" )
    {
		if(arr==='course_tags_values' || arr==='course_instructor_values')
		{
			return [];
		}
		else if(arr==='course_started')
		{
			return new Date()
		}
		else if(arr==='chapter_vid')
		{
			return "blank.mp4"
		}
		else{
			
			return '';
		}
		
    }	
    else
    {
		
        if(arr==='course_tags_values')
		{
			let newArr=localStorage.getItem(arr);
			if(newArr==null){return [] }
			else{return newArr.split(',');}
		}	
		else
		{
			return localStorage.getItem(arr);

		}
    }
	
}
export function setQVariables(arr)
{
	if(location==="/quiz/update" || location==="/questions/update" )
    {
		return localStorage.getItem(arr)
	}
	else
	{
		return null;
	}
}
export const validEmailRegex = /^(?!-)(?!.*\.\.)[A-Za-z0-9._%+]+(?<!\.)@(?!-)(?!.*\.\.)[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const validPassRegex = RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{8,12}$/);
export const validTextRegex = RegExp(/[^a-zA-Z\s]/);


export function validateForm(errors) {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
}

export function generateID() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 15; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

let monthNumber = (new Date().getMonth()+1);
export const monthNames = ["Jan", "Feb", "Mar" ,"Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const d = new Date();
var n = d.getMonth();
//alert(n)
export const months= monthNames.slice(0, n+1);


	
export function getSearchData(key,keyname){
	
	localStorage.setItem('key',key)
	localStorage.setItem('keyname',keyname)
	var keyname =(keyname.trim()).replaceAll(" ", "-");
	window.location.reload(false);window.location = '/findcourse/'+keyname.toLowerCase();
	
}
  export function findCourseByTag(course_tag)
{
	 var tag=(course_tag.trim()).replaceAll('"',""); 
  	 localStorage.setItem('key','tags')
	 localStorage.setItem('tagSearch',tag);
	 tag=tag.replaceAll(" ", "-");
	 window.location.reload(false);
	 window.location = '/findcourse/'+tag.toLowerCase();
}			


export function findCourse(id,course){
	localStorage.setItem('course_searched_name',course)
	var course =(course.trim()).replaceAll(" ", "-");
	localStorage.setItem('course_searched',id);
	window.location.reload(false);
	window.location = '/courses/'+course.toLowerCase();
}
export function readCourse(id,course){
	//var course =(course.trim()).replaceAll(" ", "-");
	localStorage.setItem('course_searched',id)
	window.location.reload(false);window.location = '/readcourses/';
}

export function getString (str){
	if (typeof str !== 'string') {
        return ''; 
    }
	
	let newStr=str.replaceAll('',"\"")
	newStr=JSON.stringify(str)
	return newStr
}


export function getFindCourses(){window.location.reload(false);window.location = '/findcourse';}

export function getProfile(id,mail,name){
	name=name.toLowerCase()	
	let mail_url=generateProfileUrl(mail)
	let t=[{'profile_mail':mail},{'profile_type':'professor'},{'profile_id':id}]
	localStorage.setItem('profile_info',JSON.stringify(t));
	window.location.reload(false);window.location = '/profile/'+mail_url;
}
export function getUserDetails(email)
{
	
				axios.post(api+'/get_user', {
				'email':email,
				})
				.then((response) => 
				{
					
					//alert(response.data['session_id'])
					//console.log(response.data)
					let t=response.data
				
					if(t['status']=="guest")
					{
							localStorage.clear();
                            alert("User doesn't exists.")
							window.location.href='https://www.opengrowth.com/';

                    }
					else
					{
						t.password='';
						localStorage.setItem('ac_login_user',JSON.stringify(t));
											
						let p_info=[
						{'mentor_id':response.data['mentor_id']},
						{'profile_mail':email},
						{'profile_type':response.data['status']},
						{'profile_id':response.data['id']}]
						localStorage.setItem('profile_info',JSON.stringify(p_info));

						localStorage.setItem('login_token',response.data['session_id']);
						// window.location.reload(false);
						//alert("jhjg")
						
						
					
					var currentUrl = window.location.href;
					var urlParams = new URLSearchParams(window.location.search);

					if (currentUrl.includes("/login") && urlParams.has("action")) {
						var action = urlParams.get("action");

						if (action === "courses") {
							var name = urlParams.get("name");
							var id = urlParams.get("id");
							if (name && id) {
								window.location.href = "/courses/" + name + "/" + id;
							}
						} else if (action === "community") {
							var name = urlParams.get("name");
							if (name) {
								window.location.href = "/community/" + name;
							}
						}else if (action === "profile") {
							var name = urlParams.get("name");
							if (name) {
								window.location.href = "/profile/" + name;
							}
						}
					} else {
						if (window.location.pathname !== '/editprofile') {
							if(t.signing_as === 'entrepreneur') {
								window.location.href = '/dashboard-entrepreneur' ;
							}
							else if(t.signing_as === 'expert') {
								window.location.href = '/dashboard-expert' ;
							}
							else if(t.signing_as === 'learner') {
								window.location.href = '/dashboard-courses' ;
							}
							else {
								window.location.href = '/dashboard' ;
							}
							
//						window.location.href = t['status'] == "student" || config.logo==="Logo_CIMP" ? '/dashboard' :  '/dashboard-mentorship';
						}
					}


                   }
				
			});	

}
export function checkError(name,value)
{
		
		this.state.errors[name]=[null,'','null',undefined].includes(value)?"Please fill out this field.":"Initalize";

	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}	
		
}
export function validForm(List)
{
		let allFilled=false;
		for (let i = 0; i < List.length; i++)
		{
			let value=List[i];
			////console.log("var : "+value+", values : "+this.state[value])
			allFilled=this.checkError(value,this.state[value]);
			if (allFilled===false)
			{
				break;
			}
		}
		return allFilled
}
export function checkEnroll(search,course_id)
	{
		
		axios.post(api+'/enrollToCourse', {
		'user_id': localStorage.getItem('student_id'),
		'course_id':localStorage.getItem('course_searched'),
		'search_by':search,
		})
		.then((response) => {
		//	alert(response.data);
			return response.data;
			
		})
}
export function getBookmark()
{		
		axios.post(api+'/markCourses', {
		'user_id': user_id,
		'user_type':localStorage.getItem('status'),
		'course_id':localStorage.getItem('course_searched'),
		'search_by':'Search',
		})
		.then((response) => {
			//alert(response.data['msg']);
			return response.data["msg"];
			////console.log(response.data['msg'])
			
		})
}	
export function translateText(search)
{
		let textFound='';
		////console.log(data[[search]]);
		try{
			textFound=data[[search][0]][['Text']]
		}
		 catch (error) {
			 textFound='';
		}
		////console.log(textFound);
		return textFound;
		
}	
export function generateString  (length,characters) 
{
        let result = ' ';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
 }
export function genPassword () {
		
        const var1 ='ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        const var2='abcdefghijklmnopqrstuvwxyz'
        const var3='0123456789';
        const var4='!@#$&';
        let pass=(generateString(1,var1)).concat(generateString(6,var2),generateString(1,var3),generateString(1,var4))    
        pass=pass.replaceAll(" ",'')
        return pass;
}
export function path_academy_after () {
		
		if(localStorage.getItem('login_token')== null)
		{
              var rem_email=localStorage.getItem('rem_email')
              var rem_pass=localStorage.getItem('rem_pass')
              var hash_arr=localStorage.getItem('hash_arr')
              localStorage.clear();
              localStorage.setItem('rem_email',rem_email)
              localStorage.setItem('rem_pass',rem_pass)
              localStorage.setItem('hash_arr',hash_arr)
			  window.localStorage.removeItem('login_token');
			  //history.push('/login')
			  window.location='/'
			
         }
		 else{
			 
			
		 }
			
}
export const getCurrentTime=()=>
{
		return axios.post('/api/get_current_time')
	   .then((response) => {
           return response.data['date']
        });
       
}

export const get_name_slug=(name)=>
{
	name=name.toString();
	name=name.toLowerCase();
	name=name.trim();
	name=name.replaceAll(/[\r\n]/gm, '-')
	name=name.replaceAll(" ", '-')
	//console.log(name)
	return name;
}

export const convertDate=(val)=>
{
		var d = new Date(val);
		var date_format_str = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
		return date_format_str
}
  
export function  update_lc_mentor(mentor_data,req){

	let new_mentor_req=JSON.parse(localStorage.getItem('ac_login_user'))
	
	if(req=="verify")
	{
				if(mentor_data['status']=="mentor")
				{
						new_mentor_req['mentor_req']=0
						new_mentor_req['is_mentor']="mentor"
						
				}
	}
	else
	{
				if(mentor_data['status']=="mentor")
				{
						new_mentor_req['mentor_req']=0
						new_mentor_req['is_mentor']="mentor"
						
				}
				else
				{
					new_mentor_req['mentor_req']=1
				}
	}
				
	localStorage.setItem('ac_login_user',JSON.stringify(new_mentor_req));
		
}

export function   format_date(date,ac){
		var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		if(ac!="full")
		{
			months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		}
		let current_datetime = new Date(date)
		let formatted_date = dateOrdinal(current_datetime.getDate()) + " " + months[current_datetime.getMonth()] + " " + current_datetime.getFullYear()
		////console.log(formatted_date)
		return formatted_date
}
export function dateOrdinal(dom) {
		if (dom == 31 || dom == 21 || dom == 1) return dom + "st";
		else if (dom == 22 || dom == 2) return dom + "nd";
		else if (dom == 23 || dom == 3) return dom + "rd";
		else return dom + "th";
}
export function manage_encryption(email,key,action) {
    var src   = email;
    var key = key.toString();
	//const CryptoJS = window.CryptoJS as typeof __Crypto__;

	let res=""
	if(action=="encrypt")
	{
		res=CryptoJS.AES.encrypt(src,key).toString();

	}
	else
	{
		try{
		
		let bytes  = CryptoJS.AES.decrypt(src, key);
		//console.log(bytes)
		res = bytes.toString(CryptoJS.enc.Utf8);
		
		}
		  catch (err) {
			 localStorage.clear();
            alert("Invalid user data! ")
			window.location.href='/';
		  //console.log('UNABLE TO DECIPHER',err)
		}
		
	}
    //console.log("source : "+src);
    //console.log("key : "+key);
    //console.log("res : "+res);
    //console.log("res length : "+res.length);
    return res;
};

export function login_for_opengrowth() {
    
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let uid=urlParams.get('uid')
	let student_id=null;
	
	if(![false,undefined,null,''].includes(localStorage.getItem('login_token')))
	{
		localStorage.clear();
		/* 
		signin
		if(["admin","webinar"].includes(localStorage.getItem('login_token'))==false)
		{
			student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
		}
		else
		{
			localStorage.clear();
		}*/
	}
	if(![undefined,null].includes(uid) && student_id==null )
	{
		let date_key =new Date(Date.now()).toLocaleString().split(',')[0];
		uid=uid.replaceAll(" ", "+")
		let decrypted_email=manage_encryption(uid,date_key,"decrypt")
		if(![undefined,null,''].includes(uid) && student_id==null)
		{
			return decrypted_email
		}
		else
		{
			localStorage.clear();
            alert("Invalid user data!")
			window.location.href='/'

			//window.location = '/';
		}
	}
	

}

	 /* {
		enable: mentor_nested['chatbox']['value'],
		key: 'Messages',
		label: 'Messages',
		path: '/chatbox',
		icon: QuestionAnswerOutlinedIcon,
		items: []
	  },*/
export function getMenu(status,signing_as,if_mentor,is_mentor)

{

	console.log(signing_as);

	let dashboardPath = '/dashboard'; // Default path
    let dashboardLabel = 'Dashboard'; // Default label

    if (signing_as === 'learner') {
        dashboardPath = '/dashboard-courses';
        dashboardLabel = 'Dashboard - Courses';
    } else if (signing_as === 'entrepreneur') {
        dashboardPath = '/dashboard-entrepreneur';
        dashboardLabel = 'Dashboard - Entrepreneur';
    } else if (signing_as === 'expert') {
        dashboardPath = '/dashboard-expert';
        dashboardLabel = 'Dashboard - Expert';
    }
	let courses_list_student= [ 
		{enable:course_nested['mycourses']['value']?1:0,key: "My Courses",label: "My Courses",path: "/mycourses",icon: ArrowRightIcon},
		//{enable:course_nested['enrolledcourses']['value']?1:0,key: "Enrolled Courses",label: "Enrolled Courses",path: "/enrolledcourses",icon: ArrowRightIcon},
		//{enable:course_nested['recently-viewed']['value']?1:0,key: "Recently Viewed",label: "Recently Viewed ",path: "/recently-viewed",icon: ArrowRightIcon},
		//{enable:course_nested['bookmarked']['value']?1:0,key: "Bookmarks",label: "Bookmarks",path: "/bookmarked",icon: ArrowRightIcon},
		{enable:course_nested['trending-courses']['value']?1:0,key: "Trending Courses",label: "Trending Courses",path: "/trending-courses",icon: ArrowRightIcon},
		{enable:course_nested['findcourse']['value']?1:0,key: "Find Courses By Category",label: "Find Courses By Category",path: "/findcourse",icon: ArrowRightIcon},
		{enable:course_nested['searchcourse']['value']?1:0,key: "Find Courses",label: "Find Courses",path: "/searchcourse",icon: ArrowRightIcon},
		//{enable:course_nested['popularcourses']['value']?1:0,key: "Popular Courses",label: "Popular Courses",path: "/popularcourses",icon: ArrowRightIcon},
		{enable:course_nested['timeline']['value']?1:0,key: "Course Timeline",label: "Course Timeline",path: "/timeline",icon: ArrowRightIcon},
		];
	let courses_list_professor=[ 
		{enable:course_nested['course/add']['value']?1:0,key: "Add Courses",label: "Add Courses",path: "/course/add",icon: ArrowRightIcon},
		{enable:course_nested['course/view']['value']?1:0,key: "Edit Courses",label: "Edit Courses",path: "/course/view",icon: ArrowRightIcon},
		{enable:course_nested['findcourse']['value']?1:0,key: "Find Courses",label: "Find Courses By Category",path: "/findcourse",icon: ArrowRightIcon},
		{enable:course_nested['searchcourse']['value']?1:0,key: "Find Courses By Tags",label: "Find Courses",path: "/searchcourse",icon: ArrowRightIcon},
		];

	let mentorship_list_mentor=[ 
					{enable:mentor_nested && mentor_nested['manage-slots']['value']?1:0,key: "Manage Slots",label: "Manage Slots",path: "/manage-slots",icon: ArrowRightIcon},
					{enable: mentor_nested && mentor_nested['our-mentors']['value']?1:0,key: "Our Experts",label: "Our Experts",path: "/mentors-and-experts",icon: ArrowRightIcon},
					{enable: mentor_nested && mentor_nested['appointments']['value']?1:0,key: "Appointments",label: "Appointments",path: "/appointment",icon: ArrowRightIcon}, //appointments changed to appointment --Shrithi 05/12/2024
					{enable: mentor_nested && mentor_nested['recent-mentors']['value']?1:0,key: "Profile Visitors",label: "Profile Visitors",path: "/recent-mentors",icon: ArrowRightIcon},
					//{enable: mentor_nested & mentor_nested['popular-mentors']['value']?1:0,key: "Popular Experts",label: "Popular Experts",path: "/popular-mentors",icon: ArrowRightIcon},
					{enable: mentor_nested && mentor_nested['mentors-followed']['value']?1:0,key: "My Followers",label: "My Followers",path: "/mentors-followed",icon: ArrowRightIcon},
					{enable: mentor_nested && mentor_nested['mentors-timeline']['value']?1:0,key: "Timeline",label: "Timeline",path: "/mentors-timeline",icon: ArrowRightIcon}
	]

	let mentorship_list_student=[ 
				     	{enable: mentor_nested && mentor_nested['our-mentors']['value']?1:0,key: "Our Experts",label: "Our Experts",path: "/mentors-and-experts",icon: ArrowRightIcon},
						{enable: mentor_nested && mentor_nested['appointments']['value']?1:0,key: "Appointments",label: "Appointments",path: "/appointment",icon: ArrowRightIcon}, //appointments changed to appointment --Shrithi 05/12/2024
						{enable: mentor_nested && mentor_nested['my-mentors']['value']?1:0,key: "My Experts",label: "My Experts",path: "/my-mentors",icon: ArrowRightIcon},
						{enable: mentor_nested && mentor_nested['mentors-followed']['value']?1:0,key: "Experts Followed",label: "Experts Followed",path: "/mentors-followed",icon: ArrowRightIcon},
					//	{enable: mentor_nested && mentor_nested['popular-mentors']['value']?1:0,key: "Popular Experts",label: "Popular Experts",path: "/popular-mentors",icon: ArrowRightIcon},
						{enable: mentor_nested && mentor_nested['recent-mentors']['value']?1:0,key: "Viewed Experts",label: "Viewed Experts",path: "/recent-mentors",icon: ArrowRightIcon},
						{enable: mentor_nested && mentor_nested['mentors-timeline']['value']?1:0,key: "Timeline",label: "Timeline",path: "/mentors-timeline",icon: ArrowRightIcon}

	]	
	let mentorship_list_professor= [ 
						{enable: mentor_nested && mentor_nested['our-mentors']['value']?1:0,key: "Search Experts",label: "Search Experts",path: "/mentors-and-experts",icon: ArrowRightIcon},
					//	{enable: mentor_nested & mentor_nested['popular-Experts']['value']?1:0,key: "Popular Experts",label: "Popular Experts",path: "/popular-mentors",icon: ArrowRightIcon}
	];
	let webinar_list= [ 
		{enable:webinar_nested['all-speakers']['value']?1:0,key: "Our Speakers",label: "Speakers",path: "/all-speakers",icon: ArrowRightIcon},
		{enable:webinar_nested['og-webinars']['value']?1:0,key: "Join webinar",label: "Join webinar",path: "/og-webinars",icon: ArrowRightIcon}
	];
	let blog_list=[ 
			{enable:blog_nested['channels']['value']?1:0,key: "Channels",label: "Channels",path: "/channels",icon: ArrowRightIcon},
			{enable:blog_nested['view-blogs']['value']?1:0,key: "Popular blogs",label: "Popular blogs",path: "/view-blogs",icon: ArrowRightIcon}
	]
	let quiz_list_student=[ 
		{enable:quiz_nested['quiz']['value']?1:0,key: "Show Quiz",label: "Show Quiz",path: "/quiz",icon: ArrowRightIcon},
		{enable:quiz_nested['quiz/progress']['value']?1:0,key: "My Progress",label: "My Progress",path: "/quiz/progress",icon: ArrowRightIcon},
	];
	let cart_list_student=[ 
		{enable:course_nested['orders']['value']?1:0,key: "Proceed Order",label: "Proceed Order",path: "/orders",icon: ArrowRightIcon},
	]
	let profie_list=[ 
			{enable:1?1:0,key: "Profile",label: "Profile",path: "/my-profile",icon: ArrowRightIcon},
			{enable:1?1:0,key: "Edit Profile",label: "Edit Profile",path: "/editprofile",icon: ArrowRightIcon},
	]	
	let community_list_student=[ 
			//{enable:1?1:0,key: "Our Community",label: "Our Community",path: "https://discord.com/invite/ApvXyF7fec",icon: ArrowRightIcon},
			{enable:1?1:0,key: "My Community",label: "My Community",path: "/joined-community",icon: ArrowRightIcon},
			{enable:1?1:0,key: "Join Community",label: "Join Community",path: "/our-communities",icon: ArrowRightIcon},
			{enable:1?1:0,key: "Add Community",label: "Add Community",path: "/manage-community/add",icon: ArrowRightIcon},
			{enable:1?1:0,key: "Followers",label: "Followers",path: "/connections",icon: ArrowRightIcon},
			//{enable:1?1:0,key: "Forum",label: "Forum",path: "/discussion-forum",icon: ArrowRightIcon},
		//	{enable:1?1:0,key: "Group Chat",label: "Group Chat",path: "/group-chat",icon: ArrowRightIcon},
		//	{enable:1?1:0,key: "Messages",label: "Messages",path: "/community-chat",icon: ArrowRightIcon}
			]
	let community_list_professor=[ 
			//{enable:1?1:0,key: "Our Community",label: "Our Community",path: "https://discord.com/invite/ApvXyF7fec",icon: ArrowRightIcon},
			{enable:1?1:0,key: "My Community",label: "My Community",path: "/joined-community",icon: ArrowRightIcon},
			{enable:1?1:0,key: "Join Community",label: "Join Community",path: "/our-communities",icon: ArrowRightIcon},
			{enable:1?1:0,key: "Add Community",label: "Add Community",path: "/manage-community/add",icon: ArrowRightIcon},
			{enable:1?1:0,key: "Followers",label: "Followers",path: "/connections",icon: ArrowRightIcon},
			//{enable:1?1:0,key: "Forum",label: "Forum",path: "/discussion-forum",icon: ArrowRightIcon},
			{enable:1?1:0,key: "Group Chat",label: "Group Chat",path: "/group-chat",icon: ArrowRightIcon},
		//	{enable:1?1:0,key: "Messages",label: "Messages",path: "/community-chat",icon: ArrowRightIcon}
		]

	let lists = [
		{
            enable: 1,
            key: 'Dashboard',
            label: 'Dashboard', // Use the dynamic label
            path: dashboardPath,    // Use the dynamic path
            icon: DashboardOutlinedIcon,
            items: []
        },
	  {
		enable: config['course']['value'] && config['logo'] === 'Logo_CIMP' ?1:0,
		key: 'CIMP',
		label: 'CIMP',
		icon: MenuBookIcon,
		items:
		  status === 'professor' ? courses_list_professor :
		  courses_list_student
	  },
	  {
		enable: config['course']['value'] && config['logo'] === 'Logo_CIMP'?1:0,
		key: 'CIMP BIIF',
		label: 'CIMP BIIF',
		icon: FaBookReader,
		items:
		  status === 'professor' ? courses_list_professor :
		  courses_list_student
	  },
	  {
		enable: config['course']['value'] && config['logo'] === 'Logo_CIMP'?1:0,
		key: 'CIMP Foundation',
		label: 'CIMP Foundation',
		icon: FaSlack,
		items:
		  status === 'professor' ? courses_list_professor :
		  courses_list_student
	  },
	  {
		enable: config['mentorship']['value'] && config['logo'] != 'Logo_CIMP' ?1:0,
		key: 'Mentorship',
		label: 'Experts on Demand',
		icon: FaUserFriends,
		items:
		  if_mentor === 'mentor' || signing_as === 'expert' ? mentorship_list_mentor :
		  status === 'student' ? mentorship_list_student :
		  mentorship_list_professor
	  },
	  {
		enable: config['course']['value'] && config['logo'] != 'Logo_CIMP' ?1:0,
		key: 'Courses',
		label: 'Courses',
		icon: MenuBookIcon,
		items:
		  status === 'professor' ? courses_list_professor :
		  courses_list_student
	  },
	  {
		enable: config['community']['value']  && config['logo'] != 'Logo_CIMP' ?1:0,
		key: 'Community',
		label: 'Community',
		icon: HiUserGroup,
		items:
		  status === 'professor' ? community_list_professor :
		  community_list_student
	  },

	{/*
		enable: 1,
		key: 'Hub',
		label: 'Hub',
		path: '/hub',
		icon: DashboardOutlinedIcon,
		items: []
	 */ },
	  {
		enable: config['webinar']['value']?1:0,
		key: 'Webinars',
		label: 'Webinars',
		icon: FaPhotoVideo,
		items: webinar_list
	  },
	  {
		enable: config['blog']['value']?1:0,
		key: 'Blogs',
		label: 'Blogs',
		icon: FaMoneyCheck,
		items: blog_list
	  },
	  {
		  enable: config['logo'] != 'Logo_CIMP'?1:0 ,
		  key: "Messages",
		  label: "Messages",
		  path: "/community-chat",
		  icon: QuestionAnswerOutlinedIcon,
		  items: []
	  },
	  {
	//	enable: status === 'student' && config['quiz']['value']?1:0,
		enable: 0,
		key: 'Quiz',
		label: 'Quiz',
		icon: FaDiceD6,
		items:quiz_list_student
	  },
	  {
		enable: status === 'student' && course_nested['orders']['value']?1:0,
		key: 'My Cart',
		label: 'My Cart',
		icon: FaShoppingCart,
		items:cart_list_student
	  },
	  {
		enable: 1,
		key: 'Account Settings',
		label: 'Account Settings',
		icon: FaUserCircle,
		items: profie_list
	  },
	  {
		enable: 1,
		key: 'Logout',
		label: 'Logout',
		path: '/',
		icon: FaSignInAlt,
		items: []
	  }
	];
	return lists;

}

export function get_chat_id(receiver_arr) {
    let sender = '';
    let sender_id = '';
    let sender_status = '';

    const login_token = localStorage.getItem('login_token');
    if (!["admin", "webinar", null].includes(login_token)) {
        const ac_login_user = JSON.parse(localStorage.getItem('ac_login_user'));
        sender = ac_login_user.email;
        sender_id = ac_login_user.id;
        sender_status = ac_login_user.status;
    }

    let str1 = '';
    let str2 = '';

	let recev_is = receiver_arr.hasOwnProperty('user_is') ? receiver_arr['user_is'] : receiver_arr['status'];
    let recev_id = receiver_arr['id'];

    recev_is = recev_is == 'student' ? 'student' : 'professor';
    recev_id = receiver_arr['id'];

    if (parseInt(recev_id) < parseInt(sender_id)) {
        str1 = recev_is.toLowerCase().charAt(0) + recev_id;
        str2 = sender_status.toLowerCase().charAt(0) + sender_id;
    } else {
        str2 = recev_is.toLowerCase().charAt(0) + recev_id;
        str1 = sender_status.toLowerCase().charAt(0) + sender_id;
    }

    const chat_id = `${str1}_${str2}`;
    //console.log(`chat_id - ${chat_id}, recev_is - ${recev_is}, recev_id - ${recev_id}`);
    //console.log(`chat_id - ${chat_id}, sender_status - ${sender_status}, sender_status - ${sender_id}`);

    return chat_id;
}

export function stringToColorOld(str){
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = Math.floor(Math.abs((Math.sin(hash) * 10000) % 1 * 16777216)).toString(16);
  return `#${'000000'.substr(0, 6 - color.length)}${color}`;
};

export function stringToColor(str) {
  const predefinedColors = ["#1b5e20", "#01579b", "#e65100", "#c62828", "#7b1fa2"];

  let hash = 0;
  if (str) { 
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const colorIndex = Math.abs(hash) % predefinedColors.length;
    return predefinedColors[colorIndex];
  } else {
    return predefinedColors[0]; 
  }
}

export function generateProfileUrl(email) {
  let profileUrl = email;
  if (email !== '') {
    const [localPart, domain] = email.split('@');
    const domainRefine = domain.endsWith('.com') ? domain.slice(0, -4) : domain;
    profileUrl = domainRefine !== 'gmail' ? `${localPart}-${domainRefine}` : localPart;
    profileUrl = profileUrl.replace(/\W+/g, '-').replace(/[^a-zA-Z0-9]+/g, '-');
  }
  return profileUrl;
}
export function sentenceCase(text) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
export function generateCourseUrl(course) {
  let course_nm=course
  let course_url=course_nm
  if (course_url !== '') {
    course_url = course_url.trim();
    course_url = course_url.toLowerCase();
    course_url = course_url.replace(/[^a-z0-9-]+/g, '-');
    course_url = course_url.replace(/^-+|-+$/g, ''); 
//	alert(typeof(course_nm)+" - "+course_nm+" - "+course_url)
    return course_url;
  }
  return '';
}

export let razorpay_credential={
	'api_key':'rzp_test_yuk1IMRMWxLWrL',
	'key_secret':'Xn3clix91gf08Utl2ym1KR1x',
	'payment_button_id':'pl_MsqTLd3frKJtpq',
	'old':["pl_K9nh6BcVsJONva","pl_MsqTLd3frKJtpq","pl_JmqIFkH84H66A6"]
}