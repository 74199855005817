import React, { useEffect } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import banner from "../assets/About us/about_us_banner.png";
import bannerVideo from "../assets/About us/Aboutus_banner.mp4"; // Import your video
import frameworkImage from "../assets/About us/about_us_venture1.png";
import servicesImage from "../assets/About us/about_us_venture2.jpg";
import useGlobalStyles from '../styles/CourseStyles';
import missionImage from "../assets/About us/about_us_mission.jpg"; // Assuming the image file name
import visionImage from "../assets/About us/about_us_vision.jpg";
import WhyChooseOpenGrowth from './WhyChooseOpengrowth';
import MissionVisionSection from './MissionVisionSection';

const useStyles = makeStyles((theme) => ({
  '@keyframes floatWave': {
    '0%': {
      transform: 'translate(0, 0) scale(1)',
    },
    '25%': {
      transform: 'translate(5px, 10px) scale(1.01)',
    },
    '50%': {
      transform: 'translate(-5px, 15px) scale(1.02)',
    },
    '75%': {
      transform: 'translate(5px, 10px) scale(1.01)',
    },
    '100%': {
      transform: 'translate(0, 0) scale(1)',
    },
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row', // Keep as row to have image and text side by side
    alignItems: 'center',
    justifyContent: 'flex-start', // Align content to the left
    padding: theme.spacing(4),
    color: '#FFFFFF',
    height: '550px',
    overflow: 'hidden', // Ensure video doesn't overflow
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      padding: theme.spacing(2),
    },
  },
  videoBackground: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transform: 'translate(-50%, -50%)',
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Optionally hide video on small screens
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(37, 56, 124, 0.5)', // Semi-transparent overlay
    zIndex: 1,
  },
  imageContainer: {
    flex: 1,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '50%',
    zIndex: 2, // Above video and overlay
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginBottom: theme.spacing(4),
    },
  },
  image: {
    width: '80%',
    height: 'auto',
    borderRadius: '8px',
    animation: `$floatWave 5s ease-in-out infinite`,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      animation: 'none', // Disable animation on small screens
    },
  },
  contentContainer: {
    flex: 1,
    paddingLeft: theme.spacing(4),
    maxWidth: '50%',
    zIndex: 2, // Above video and overlay
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      textAlign: 'center',
      maxWidth: '100%',
    },
  },
  heading: {
    width: 'auto',
    fontWeight: 'bold',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  section: {
    marginTop: '5rem',
    backgroundColor: '#FFFFFF',
    color: '#25387c',
    textAlign: 'left',
  },
  sectionHeading: {
    color: '#25387c',
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: theme.spacing(3, 2),
    },
  },
  imagesRow: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(0, 2),
    borderRadius: '12px',
    background: 'linear-gradient(to bottom, #f9bb02, #fff)',
    
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imageWithText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '45%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      marginBottom: theme.spacing(4),
    },
  },
  imageHeading: {
    marginBottom: theme.spacing(2),
    color: '#25387c', // Adjust color as needed
    textAlign: 'center',
  },
  sectionImage: {
    width: '100%',
    height: '100%',
    border: '4px solid rgb(21, 22, 26)',
    borderRadius: '8px',
    boxShadow: '0 6px 12px rgba(0,0,0,0.6)',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginBottom: theme.spacing(4),
    },
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
    },
  },
  whySection: {
    padding: theme.spacing(6),
    backgroundColor: '#FFFFFF',
    color: '#25387c',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginTop: theme.spacing(4),
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  card: {
    backgroundColor: '#25387c',
    color: '#FFFFFF',
    padding: theme.spacing(4),
    borderRadius: '8px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '500',
  },
  additionalPoints: {
    marginTop: theme.spacing(4),
    fontSize: '16px',
    lineHeight: 1.8,
  },
  checkIcon: {
    color: '#25387c',
    marginRight: theme.spacing(1),
  },
  h6font: {
    color: '#fff',
  },
  tick: {
    display: 'inline-flex',
    width: 'auto',
    height: '100%',
    margin: theme.spacing(0.5, 2),
    fontSize: '1.5em',
  },
  missionvisioncontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    backgroundColor: '#ffffff',
  },
  missionVisionCard: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: theme.spacing(2, 0),
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  textSection: {
    flex: 2,
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  imageSection: {
    flex: 2,
    padding: theme.spacing(2, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  puzzleImage: {
    maxWidth: '100%',
    width: '100%',
    height: '60%',
    borderRadius: theme.shape.borderRadius,
  },
  list: {
    margin: 12,
    padding: 0,
    listStyleType: 'disc',
    marginLeft: theme.spacing(4),
  },
}));

const AboutUsNew = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  useEffect(() => {
    const mainTitle = "About us | OpenGrowth.AI";
    const subtitle = "Learn about OpenGrowth.AI, where innovation meets expertise. We help startups achieve their goals with AI-driven strategies and expert guidance.";
    document.title = `${mainTitle} - ${subtitle}`;
  }, []);

  return (
    <>
      {/* Main About Us Section -- banner with Video Background */}
      <Box className={classes.container}>
        {/* Video Background */}
        <video
          autoPlay
          muted
          loop
          className={classes.videoBackground}
        >
          <source src={bannerVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Overlay */}
        <Box className={classes.overlay} />

        {/* Content Container */}
        <Box className={classes.contentContainer}>
          <Typography variant="h3" className={`${styleClasses.h1heading} ${classes.heading}`}>
            About Us
          </Typography>
          <Typography variant="h6" className={`${styleClasses.h6banner} ${classes.description}`}>
          OpenGrowth.AI is your Revenue Growth Partner that offers Pay-as-you-go Growth Services powered by a curated GenAI crew of GTM AI Agents and Expert Intelligence of Fractional Growth Experts supported by Full Stack Venture Assistance Services for high-growth startups and matured enterprises.
          </Typography>
        </Box>
      </Box>

      {/* New Section with Heading and Images */}
      <Box className={classes.section}>
        <Typography variant="h4" className={`${styleClasses.h4font} ${classes.sectionHeading}`}>
        Full Stack Growth Services
        </Typography>
        <Box className={classes.imagesRow}>
          {/* First Image with Typography */}
          <Box className={classes.imageWithText}>
            <Typography variant="h5" className={`${styleClasses.h5fontPara} ${classes.imageHeading}`}>
              Growth Selling as a Service
            </Typography>
            <img
              src={frameworkImage}
              alt="Venture Assistance Framework"
              className={classes.sectionImage}
            />
          </Box>

          {/* Second Image with Typography */}
          <Box className={classes.imageWithText}>
            <Typography variant="h5" className={`${styleClasses.h5fontPara} ${classes.imageHeading}`}>
              Venture Assistance Services
            </Typography>
            <img
              src={servicesImage}
              alt="Venture Assistance Services"
              className={classes.sectionImage}
            />
          </Box>
        </Box>
      </Box>

      {/* Why Choose OpenGrowth Section */}
      <WhyChooseOpenGrowth />

      {/* Mission and Vision Sections */}
      <MissionVisionSection />
    </>
  );
};

export default AboutUsNew;
