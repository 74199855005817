    // src/components/FeaturesSection.jsx

    import React from 'react';
    import {
        Box,
        Typography,
        Card,
        Button,
        Grid,
        CardContent,
        CardMedia,
    } from '@material-ui/core';
    import { makeStyles, useTheme } from '@material-ui/core/styles';
    import useGlobalStyles from '../styles/CourseStyles'; // Ensure this path is correct
    import featureImage from '../assets/Venture/growth_selling.png'; // Replace with your actual image path or use a placeholder
    import { BsAlignBottom } from 'react-icons/bs';
    import { Link } from 'react-router-dom';

    const useStyles = makeStyles((theme) => ({
        // Styles for FeaturesSection
        featuresSectionContainer: {
            borderRadius: '8px',
            marginTop: '5rem',
            padding: theme.spacing(4),
            boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
            backgroundColor: '#fff', // Optional: Add a background color for better contrast
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },
        sectionHeading: {
            fontWeight: 'bold',
            marginBottom: theme.spacing(2),
            color: '#25387c', // Dark blue color
        },
        subHeading: {
            fontWeight: '600',
            marginBottom: theme.spacing(1),
            color: '#25387c',
        },
        description: {
            marginBottom: theme.spacing(2),
            color: '#555', // Darker gray for readability
        },
        benefitsList: {
            listStyleType: 'disc',
            paddingLeft: theme.spacing(3),
            '& li': {
                marginBottom: theme.spacing(1),
            },
        },
        benefitItem: {
            color: '#555',
        },
        featureCard: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Ensure content is spaced out
            backgroundColor: '#f5f5f5',
            borderRadius: '12px',
            // boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            height: '75%',
            boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
            "&:hover": {
        transform: "translateY(-5px)",
        boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
        },
    },
        featureCardMedia: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            
        },
        featureCardTitle: {
            fontWeight: '600',
            color: '#25387c',
            marginBottom: theme.spacing(1),
        },
        featureCardDescription: {

            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        getStartedButton: {
            backgroundColor: '#f5f5f5',
            color: '#000',
            borderRadius: '50px',
            textTransform: 'none',
            padding: '8px 16px',
            marginTop: '8px',
            '&:hover': {
                backgroundColor: '#f9bb02',
                transform: 'translateY(-2px)',
            },
        },
        h5: {
            marginBottom: theme.spacing(1),
        },
        h5font: {
            textAlign: 'left',
            color: '#25387c',
        },
        h6font: {
            color: '#25387c'
        },
        leftColumnBox: {
            padding: '0 0px 8px 0px',
            marginBottom: '1.5rem', // Add spacing between boxes
        },
        leftGrid: {
            display: 'flex',
            flexDirection: 'column',
            padding: '32px !important',
            paddingLeft: '16px !important',
            justifyContent: 'center',
        }
    }));

    const GrowthSelling = () => {
        const classes = useStyles();
        const styleClasses = useGlobalStyles();
        const theme = useTheme();

        return (
            <Box className={classes.featuresSectionContainer}>
                {/* Section Heading */}

                <Typography
                    variant="h6"
                    className={`${styleClasses.h4font} ${classes.h6font}`}
                    gutterBottom
                >
                    What is Growth Selling?
                </Typography>

                {/* Two-Column Layout */}
                <Grid container spacing={4} alignItems="stretch"> {/* Ensure both columns stretch to the same height */}
                    {/* Left Column - 60% */}
                    <Grid item xs={12} md={7} className={classes.leftGrid}>
                        <Box className={classes.leftColumnBox}>

                            <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}    >
                            Growth Selling combines the best of Agentic AI, Expert Intelligence and Expert Networks to drive Revenue Growth on a Pay-as-you-go model.
                            </Typography>
                        </Box>
                        <Box className={classes.leftColumnBox}>
                            <Typography variant="h5" className={`${styleClasses.h5fontPara} ${classes.h5font}`} gutterBottom>
                            AI GTM Agents 
                            </Typography>
                            <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Agent-based architecture that can handle multiple use cases within the GTM function within a single, configurable system. Curating a Crew of AI agents that are available in the marketplace for unique use cases in the GTM journey combined with In House AI Agents created by AISquare, an OpenGrowth Ventures initiative.

                            </Typography>
                        </Box>
                        <Box className={classes.leftColumnBox}>
                            <Typography variant="h5" className={`${styleClasses.h5fontPara} ${classes.h5font}`} gutterBottom>
                            Expert Intelligence 
                            </Typography>
                            <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Growth Selling taps into a global pool of expert talent on how an approach rooted in Expert Intelligence (EI) can take B2B companies from data-rich and insight-poor to organizations that help build trust with customers and create sustainable growth.

                            </Typography>
                            <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Expert Intelligence requires working backward from the desired customer outcome, shifting focus from product features to actual revenue outcome.

                            </Typography>
                        </Box>
                        <Box className={classes.leftColumnBox}>
                            <Typography variant="h5" className={`${styleClasses.h5fontPara} ${classes.h5font}`} gutterBottom>
                            Growth Expert Networks 
                            </Typography>
                            <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Networks of Growth Experts applying AI to GTM leveraging expertise to train and validate GTM models. Augment GTM Agentic AI with human relationships and networks to build trust based selling.

                            </Typography>
                        </Box>
                    </Grid>

                    {/* Right Column - 40% */}
                    <Grid item xs={12} md={5} style={{paddingTop: '6rem', background: 'linear-gradient(to bottom, #f9bb02, white)', borderRadius: '12px'}}>
                        <Card className={classes.featureCard}>
                            <CardMedia
                                className={classes.featureCardMedia}
                                image={featureImage} // Replace with your desired image
                                title="Growth Expert"
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    export default GrowthSelling;
