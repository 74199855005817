// src/components/GrowthStudioHome.jsx

import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    Button,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    CircularProgress,
    Grid
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import GrowthBenefitsCard from '../Experts/GrowthBenefitsCard';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/Venture/growth_advisory_section.jpg';
import bannerImage from '../assets/Venture/Growth_advisory.png';
import axios from 'axios';
import FeaturesSection from './FeatureSection';
import WhyHireGrowthAssociate from './WhyHireGrowthAssociate';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
    mainCard: {
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        borderRadius: '8px',
        overflow: 'hidden',
        margin: 'auto',
        border: 'none',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(8),
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: 8,
        },
    },
    // Updated banner styles
    bannerContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        border: 'none',
        paddingLeft: '0rem',
        borderRadius: '4px',
        alignItems: 'flex-start',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            flexDirection: 'column', // Ensure vertical stacking on small screens
            alignItems: 'center',
        },
    },
    imageBox: {
        width: '100%',
        height: '550px', // Adjust the height as needed
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: '700px',
        },
        position: 'relative',
    },
    bannerImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    contentOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 'auto',
        height: 'auto',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(4.5, 4, 16, 4),
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            top: 20,
            justifyContent: 'flex-start',
            padding: theme.spacing(2),
        },
    },
    contentBox: {
        backgroundColor: 'transparent',
        color: 'white', // Adjust text color based on image
        maxWidth: '100%', // Adjust as needed
        marginTop: '0em',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            textAlign: 'left',
        },
    },
    bannerButton: {
        marginTop: theme.spacing(2),
        borderRadius: '50px',
        backgroundColor: '#f9bb02',
        color: 'black',
        fontWeight: 600,
        fontSize: '1rem',
        padding: '8px 24px',
        textTransform: 'none',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#f9bb02',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
            padding: '6px 12px',
        },
    },
    subscribeRibbon: {
        backgroundColor: '#25387c', // Standout color for the ribbon
        width: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: theme.spacing(-4), // Adjust to overlay on the image
        marginBottom: theme.spacing(3),
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 4, // Higher z-index to appear above image and content
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            textAlign: 'center',
            padding: theme.spacing(2),
            marginTop: theme.spacing(0),
        },
    },
    h3font: {
        fontWeight: '500',
        color: '#f9bb02',
        border: 'none',
        width: '713px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
    },
    h4font: {
        padding: '8px 12px',
        fontWeight: '600',
        fontSize: '49px',
        border: 'none',
    },
    h6font: {
        color: '#25387c',
        marginBottom: theme.spacing(2),
    },
    featureCard: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(3),
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        backgroundColor: '#ffffff',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        width: '100%',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transform: 'translateY(-2px)',
            backgroundColor: '#0000000a',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            textAlign: 'center',
        },
    },
    title: {
        fontWeight: 'bold',
        fontSize: '2.6rem',
        fontFamily: 'MyCustomFont, sans-serif',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8em',
        },
    },
    searchBar: {
        backgroundColor: '#fff',
        borderRadius: '50px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '400px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '50px',
            height: '2.1em',
            padding: '0 14px',
        },
        '& .MuiInputBase-input': {
            padding: 0,
            height: '100%',
            fontSize: '0.9rem',
        },
    },
    searchBarBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2.5rem',
        [theme.breakpoints.down('sm')]: {
            marginTop: '16px',
        },
    },
    chipsearchbox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            paddingLeft: '4px',
            paddingRight: '0px',
        },
    },
    searchButton: {
        marginLeft: theme.spacing(1),
        backgroundColor: '#f9bb02',
        color: '#000',
        height: '2.4em',
        fontWeight: 'bold',
        borderRadius: '50px',
        '&:hover': {
            backgroundColor: '#d6a302',
        },
        whiteSpace: 'nowrap',
    },
    newHeading: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    filterChips: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
        },
    },
    filterChip: {
        [theme.breakpoints.down('sm')]: {
            flex: '0 0 40%',
            boxSizing: 'border-box',
            marginBottom: theme.spacing(1),
            paddingLeft: theme.spacing(0.4),
            paddingRight: theme.spacing(0.4),
        },
    },
    loadMoreButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    loadMoreButton: {
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderRadius: '50px',
        padding: '8px 24px',
        minWidth: '150px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            border: `2px solid ${theme.palette.primary.main}`,
        },
    },
    loadingIndicator: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        justifyContent: 'center',
    },
    promoCardContainer: {
        display: 'flex',
        backgroundColor: '#25387c',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderRadius: '4px',
        border: 'none',
        marginTop: theme.spacing(6),
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    promoCard: {
        display: 'flex',
        border: 'none',
        flexDirection: 'column',
        backgroundColor: '#25387c',
        color: '#fff',
        padding: theme.spacing(4),
        borderRadius: '12px',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(1.5),
            textAlign: 'center',
        },
    },
    promoImage: {
        width: '300px',
        height: '50%',
        marginLeft: theme.spacing(4),
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '200px',
            marginLeft: 0,
            marginTop: theme.spacing(3),
        },
        objectFit: 'cover',
    },
    promoTitle: {
        fontSize: '1.8rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.4rem',
        },
    },
    promoSubtitle: {
        fontSize: '1rem',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        },
    },
    promoButton: {
        marginTop: '16px',
        padding: '12px 16px',
        alignSelf: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
        },
    },
    dialog: {
        padding: '32px',
    },
    textfield: {
        padding: '8px 0px',
    },

    // ===================== NEW SECTION STYLES =====================
    sectionContainer: {
        margin: '5rem 0px',
        padding: '2.5rem 32px',
        borderRadius: '6px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)',
    },
    gradientImageWrapper: {
        background: 'linear-gradient(to bottom, #f9bb02, #fff)', // adjust the colors as needed
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(4),
        borderRadius: '8px',
      },
      centerImage: {
        display: 'block',
        margin: '40px auto',
        maxWidth: '50%',
        height: 'auto',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
        },
      },
    discoveryButton: {
        marginTop: theme.spacing(4),
        borderRadius: '50px',
        backgroundColor: '#f9bb02',
        color: 'black',
        fontWeight: 600,
        fontSize: '1rem',
        padding: '8px 24px',
        textTransform: 'none',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#f9bb02',
        },
    },
}));

const emailRegex = /^(?!-)(?!.*\.\.)[A-Za-z0-9._%+]+(?<!\.)@(?!-)(?!.*\.\.)[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

// BannerSlide Component
const BannerSlide = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [calendlyUrl] = useState('https://calendly.com/opengrowth-support/30min');

    // State for subscribe modal
    const [openSubscribe, setOpenSubscribe] = useState(false);
    const [email, setEmail] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState(null); // null, 'success', 'error'
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [emailError, setEmailError] = useState(''); // New state for email error

    const handleOpenSubscribe = () => {
        setOpenSubscribe(true);
    };

    const handleCloseSubscribe = () => {
        setOpenSubscribe(false);
        setSubscriptionStatus(null);
        setEmail('');
        setIsLoading(false);
        setEmailError(''); // Clear any existing error
    };

    const handleSubscribe = async () => {
        // Reset error message
        setEmailError('');

        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post('/api/send/email', { email });
            console.log(response);

            setEmail('');
            setSubscriptionStatus(response.data.status);
            // Optionally, you can show a success message or perform other actions
            setTimeout(() => setOpenSubscribe(false), 1500);
        } catch (error) {
            console.error('Subscription error:', error);
            setSubscriptionStatus('error');
            setEmailError('Subscription failed. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
                {/* Banner Image */}
                <Box className={classes.imageBox}>
                    <img
                        src={bannerImage}
                        alt="Growth Studio Banner"
                        className={classes.bannerImage}
                    />

                    {/* Content Overlay */}
                    <Box className={classes.contentOverlay}>
                        <Box className={classes.contentBox}>
                            <Typography
                                variant={isSmall ? 'h4' : 'h1'}
                                gutterBottom
                                className={`${styleClasses.h1heading} ${classes.h3font}`}
                            >
                                Growth Advisory
                            </Typography>
                            <Typography
                                variant={isSmall ? 'h4' : 'subtitle1'}
                                gutterBottom
                                className={`${styleClasses.h6banner}`}
                            >
                                Your Growth Partner in GTM AI Operations, Workforce Upskilling, Change Management and Industry-Leading Expert Communities.
                            </Typography>

                            <PopupButton
                                url={calendlyUrl}
                                rootElement={document.getElementById('root') || undefined}
                                text="Book a Discovery Call"
                                className={`${styleClasses.individualButton} ${classes.promoButton}`}
                                variant="contained"
                            />
                        </Box>
                    </Box>

                    {/* Subscribe Ribbon */}
                    <Box className={classes.subscribeRibbon}>
                        <Typography variant="h6" className={styleClasses.h6font} style={{ color: '#f9bb02' }}>
                            Don’t miss out on exclusive updates, free resources, and special offers—delivered
                            right to you!
                        </Typography>
                        <Button
                            variant="contained"
                            className={styleClasses.individualButton}
                            onClick={handleOpenSubscribe}
                        >
                            Subscribe Now
                        </Button>
                    </Box>
                </Box>

                {/* Subscribe Modal */}
                <Dialog
                    open={openSubscribe}
                    onClose={handleCloseSubscribe}
                    maxWidth="sm"
                    classes={{
                        paper: classes.dialog, // Apply custom styles to the Paper component inside the Dialog
                    }}
                >
                    <Typography className={`${styleClasses.h6banner} ${classes.h6dialog}`}>
                        Subscribe to Our Newsletter
                    </Typography>
                    <DialogContent className={classes.textfield}>
                        {subscriptionStatus === 'success' ? (
                            <Typography style={{ color: 'green' }}>
                                Subscription successful! Thank you for subscribing.
                            </Typography>
                        ) : (
                            <>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Email Address"
                                        type="email"
                                        variant="outlined"
                                        required
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            if (emailError) {
                                                setEmailError(''); // Clear error when user starts typing
                                            }
                                        }}
                                        aria-label="Email Address"
                                        error={Boolean(emailError)}
                                        helperText={emailError}
                                    />
                                </FormControl>
                                {isLoading && (
                                    <Box display="flex" justifyContent="center" mt={2}>
                                        <CircularProgress />
                                    </Box>
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {subscriptionStatus === 'success' && (
                            <Button onClick={handleCloseSubscribe} color="primary" className={styleClasses.subtitlefont}>
                                Thankyou
                            </Button>
                        )
                        }
                        {subscriptionStatus !== 'success' && (
                            <>
                                <Button onClick={handleCloseSubscribe} color="primary" className={styleClasses.subtitlefont}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleSubscribe}
                                    color="primary"
                                    variant="contained"
                                    disabled={!email || isLoading}
                                    className={styleClasses.businessButton}
                                >
                                    Subscribe
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

// GrowthAdvisory Component
const GrowthAdvisory = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const history = useHistory();
    const [calendlyUrl] = useState('https://calendly.com/opengrowth-support/30min');

    useEffect(() => {
        const mainTitle = 'Growth Advisory | OpenGrowth.AI';
        const subtitle = "Access expert growth advisory services at OpenGrowth.AI. Transform your business strategies with tailored solutions and guidance.";
        document.title = `${mainTitle} - ${subtitle}`;
    }, []);

    return (
        <Box style={{ padding: '8px', paddingTop: 0 }}>
            {/* Banner Carousel */}
            <BannerSlide />

            {/* BELOW-BANNER SECTION (replicating the screenshot) */}
            <Box className={classes.sectionContainer}>
                <Typography variant="h4" className={styleClasses.h4font} gutterBottom>
                    What is Growth Advisory?
                </Typography>

                <Typography variant="subtitle1" className={styleClasses.description} paragraph>
                    OpenGrowth Advisory is a ResTech (Research Technology) Practice led by an Open Network of Growth Experts, GTM AI Ops Experts and Executives.GenAI GTM Ops is the specialized practice of making Generative AI products successful in the market by bridging technical capabilities with strategic business operations. Open Talent Networks of GTM Experts will power the future of work in the age of AI/ML and Open Systems for collaboration and Innovation. It also helps in creating bespoke Expert Communities for Industry Verticals such as Data Centers, GCCs.
                </Typography>

                {/* Middle Image */}

                {/* Middle Image with Gradient Background */}
                <div className={classes.gradientImageWrapper}>
                    <img
                        src={promoImageSrc}
                        alt="OpenGrowth's Comprehensive Growth Advisory Services"
                        className={classes.centerImage}
                    />
                </div>

                <Typography variant="subtitle1" className={styleClasses.description} paragraph>
                    It is focused on working with Industry Bodies in the AI Data Center space to leverage industry best
                    practices and reference designs to create a pod for autonomous edge compute data centers and
                    build an Experience Center for a referenceable Micro AI Data Center Design.
                </Typography>

                <Typography variant="subtitle1" className={styleClasses.description} paragraph>
                    The Growth Advisory is also focused on “Human Centered Growth” to create a psychologically safe
                    change management of the workforce in the age of AI to co-work with AI Agents and also upskill
                    the workforce through career advisory and Gen AI-powered learning technologies.
                </Typography>

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* Book a Discovery Call Button */}
                    <PopupButton
                        url={calendlyUrl}
                        rootElement={document.getElementById('root') || undefined}
                        text="Book a Discovery Call"
                        className={styleClasses.individualButton}
                        styles={{ padding: "12px 16px" }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default GrowthAdvisory;
