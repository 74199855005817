import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Container, Grid, Paper, Avatar } from '@material-ui/core';

// Importing images from the "Images" folder
import testimonialImage1 from './Images/image1.png';
import instructorImage from './Images/image2.jpg';
import testimonialImage2 from './Images/image3.png';
import testimonialImage3 from './Images/image4.png';
import testimonialImage4 from './Images/image5.png';
import comparisonChart from './Images/image6.png';

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: '#c9deff99',
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    lineHeight: 1.6,
    color: '#333',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    backgroundColor: '#c9deff99',
    padding: theme.spacing(4),
    borderRadius: 10,
  },
  highlightBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    color: 'white',
    padding: theme.spacing(2, 4),
    borderRadius: 8,
    fontSize: '18px',
    fontWeight: 'bold',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '22px',
    color: '#FFD700',
  },
  container: {
    width: '80%',
    margin: 'auto',
    padding: theme.spacing(4, 0),
  },
  headline: {
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    color: '#f20c0c',
  },
  subheadline: {
    fontSize: '1.25rem',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    color: '#555',
  },
  ctaButton: {
    display: 'block',
    margin: theme.spacing(4, 'auto'),
    backgroundColor: '#ff4500',
    color: 'white',
    padding: theme.spacing(2, 4),
    fontSize: '1.2rem',
    borderRadius: 8,
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, transform 0.2s',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#e63900',
      transform: 'scale(1.05)',
    },
  },
  features: {
    listStyle: 'none',
    padding: 0,
    margin: theme.spacing(4, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    background: '#f9bb02',
    color: 'black',
    padding: theme.spacing(2),
    borderRadius: 8,
    fontSize: '16px',
    fontWeight: 'bold',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.2)',
    },
  },
  section: {
    marginBottom: theme.spacing(6),
  },
  faq: {
    backgroundColor: '#c9deff99',
    padding: theme.spacing(3),
    borderRadius: 8,
  },
  faqHeader: {
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    backgroundColor: '#f9bb02',
    padding: theme.spacing(1),
    borderRadius: 5,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
      backgroundColor: '#f9bb02',
      color: 'black',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.2)',
    },
  },
  testimonial: {
    textAlign: 'center',
    margin: theme.spacing(4, 0),
  },
  testimonialImage: {
    borderRadius: 0,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '50%',
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: 10,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  },
  footer: {
    textAlign: 'center',
    backgroundColor: '#c9deff99',
    padding: theme.spacing(2),
  },
  footerText: {
    animation: '$blinkTextAnimation 1s infinite alternate',
    color: '#f9bb02',
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  '@keyframes blinkTextAnimation': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
}));

const LandingPage = () => {
  const classes = useStyles();

  const handleFaqClick = (event) => {
    const content = event.target.nextElementSibling;
    content.style.display = content.style.display === 'block' ? 'none' : 'block';
  };

  return (
    <div className={classes.body}>
      <div className={classes.headerContainer}>
        <Paper className={classes.highlightBox}>
          <span className={classes.icon}>📊</span> <span>900+</span> Pitch Decks Transformed
        </Paper>
        <Paper className={classes.highlightBox}>
          <span className={classes.icon}>💰</span> <span>$75M</span> in Funding
        </Paper>
      </div>

      <Container className={classes.container}>
        <section className={classes.section}>
          <Typography variant="h2" className={classes.headline}>
            Create an Investor-Ready Pitch Deck in 2 Hours
          </Typography>
          <Typography variant="h5" className={classes.subheadline}>
            Turn Your Startup Into a Funding Magnet With Proven Pitch Deck Strategies.<br />
            (A Course Worth ₹75,000+ Packed with Expert Insights and Proven Tools)
          </Typography>
          <Button
            className={classes.ctaButton}
            href="https://rzp.io/rzp/iWs2Bs1W"
            variant="contained"
          >
            Enroll Now for ₹4,999/- <del>₹75,000</del>
          </Button>
          <ul className={classes.features}>
            <li className={classes.featureItem}>
              <span className={classes.icon}>💡</span> <span>Expert Guidance</span>
            </li>
            <li className={classes.featureItem}>
              <span className={classes.icon}>🛠️</span> <span>Proven Pitch Deck Frameworks</span>
            </li>
            <li className={classes.featureItem}>
              <span className={classes.icon}>💰</span> <span>Affordable and Smart Investment</span>
            </li>
            <li className={classes.featureItem}>
              <span className={classes.icon}>🔑</span> <span>Personalized 15-Minute 1-on-1 Evaluation Session</span>
            </li>
          </ul>
        </section>

        <section className={classes.section}>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            Venkat Ramanan - Founder of PitchyDeck
          </Typography>
          <div style={{ display:'flex', justifyContent: 'center' }}>
            <Avatar alt="Instructor Venkat Ramanan" src={instructorImage} style={{ width: 150, height: 150 }} />
          </div>
          <Typography>
            <strong>Venkat Ramanan</strong>, Founder of Pitchydeck, has helped startups raise $75 million+ across Angel, VC, Pre-Series A, and Series A rounds. With years of experience and a passion for empowering entrepreneurs, Venkat will show you how to make your story irresistible to investors.
          </Typography>
        </section>

        <section className={classes.section}>
          <Typography variant="h4">Here’s What 900+ Founders Have to Say</Typography>
          <div className={classes.testimonial}>
            <img src={testimonialImage1} alt="Testimonial" className={classes.testimonialImage} />
            <Typography>"This masterclass changed the way I approach investors. Venkat’s insights are priceless!"</Typography>
          </div>
          <div className={classes.testimonial}>
            <img src={testimonialImage2} alt="Testimonial" className={classes.testimonialImage} />
          </div>
          <div className={classes.testimonial}>
            <img src={testimonialImage3} alt="Testimonial" className={classes.testimonialImage} />
          </div>
          <div className={classes.testimonial}>
            <img src={testimonialImage4} alt="Testimonial" className={classes.testimonialImage} />
          </div>
        </section>

        <section className={classes.section}>
          <Typography variant="h4">🚀 The REAL Reason Why Investors Reject Pitch Decks</Typography>
          <ul className={classes.features}>
            <li className={classes.featureItem}>
              <span className={classes.icon}>❌</span> <strong>No compelling story</strong> – Your deck lacks a narrative that captivates.
            </li>
            <li className={classes.featureItem}>
              <span className={classes.icon}>💰</span> <strong>Unclear revenue model</strong> – Investors don’t see how they'll make money.
            </li>
            <li className={classes.featureItem}>
              <span className={classes.icon}>📊</span> <strong>Bloated facts & figures</strong> – Too much data, not enough clarity.
            </li>
            <li className={classes.featureItem}>
              <span className={classes.icon}>🎨</span> <strong>Content overload & messy design</strong> – Confusing structure, poor visuals.
            </li>
            <li className={classes.featureItem}>
              <span className={classes.icon}>🔄</span> <strong>Trying to solve too many problems</strong> – Lack of focus weakens your pitch.
            </li>
          </ul>
          <Typography>
            Master investor-ready pitching with expert guidance, proven tools, and a community of like-minded founders.
          </Typography>
          <Button
            className={classes.ctaButton}
            href="https://rzp.io/rzp/iWs2Bs1W"
            variant="contained"
          >
            🔥 Secure Your Spot for ₹4,999/- 🚀 <del>₹75,000</del>
          </Button>
        </section>

        <section className={classes.section}>
          <Typography variant="h4">Why This Masterclass Is a Game-Changer for You</Typography>
          <div className={classes.imageContainer}>
            <img src={comparisonChart} alt="Comparison Chart" className={classes.image} />
          </div>
        </section>

        <section className={`${classes.section} ${classes.faq}`}>
          <Typography variant="h4">Frequently Asked Questions</Typography>
          <div>
            <Typography variant="h6" className={classes.faqHeader} onClick={handleFaqClick}>
              Q: What if I miss a session?
            </Typography>
            <Typography style={{ display: 'none' }}>
              A: No worries! Live masterclass will be recorded and accessible later.
            </Typography>
          </div>
          <div>
            <Typography variant="h6" className={classes.faqHeader} onClick={handleFaqClick}>
              Q: Will I get personal feedback on my pitch?
            </Typography>
            <Typography style={{ display: 'none' }}>
              A: Yes! Every participant gets a 15-minute 1-on-1 pitch evaluation and feedback slot.
            </Typography>
          </div>
          <div>
            <Typography variant="h6" className={classes.faqHeader} onClick={handleFaqClick}>
              Q: Is this course suitable for early-stage startups?
            </Typography>
            <Typography style={{ display: 'none' }}>
              A: Absolutely! This course is tailored for startups at any stage looking to refine their pitch.
            </Typography>
          </div>
        </section>

        <Button
          className={classes.ctaButton}
          href="https://rzp.io/rzp/iWs2Bs1W"
          variant="contained"
        >
          Enroll Now for ₹4,999/- <del>₹75,000</del>
        </Button>
      </Container>

      <footer className={classes.footer}>
        <Typography className={classes.footerText}>Don’t Wait – Seats Are Filling Fast!</Typography>
      </footer>
    </div>
  );
};

export default LandingPage;
