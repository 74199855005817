// src/components/WhyHireGrowthAssociate.jsx

import React, { useState } from 'react';
import {
    Box,
    Typography,
    Card,
    Grid,
    CardContent,
    CardMedia,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles';
import featureImage1 from '../assets/associate1.webp';
import featureImage2 from '../assets/associate2.webp';

// Import the background image (with the yellow lines) here:
import headerBG from '../assets/Venture/Growth_agent_AI.png';
import { PopupButton } from 'react-calendly';

const useStyles = makeStyles((theme) => ({
    // ====== HEADER STYLES ======
    headerContainer: {
        position: 'relative',
        backgroundImage: `url(${headerBG})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'left center',

        minHeight: '240px', // Adjust as needed
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '5rem',
        padding: theme.spacing(4, 8),

        [theme.breakpoints.down('sm')]: {
            minHeight: '180px',
        },
    },
    textContainer: {

        marginLeft: theme.spacing(2),
        textAlign: 'left',
        color: '#25387c',
    },
    headerSubtitle: {
        fontSize: '1rem',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        },
    },
    headerTitle: {
        fontSize: '2.4rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.6rem',
        },
    },

    // ====== EXISTING STYLES ======
    container: {
        borderRadius: '8px',
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        color: '#25387c',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    heading: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(4),
        color: '#25387c',
        textAlign: 'center',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: '#ffffff',
        border: 'none',
        color: '#25387c',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        height: '100%',
    },
    cardMedia: {
        height: 200,
        width: '100%',
        borderRadius: '4px',
        objectFit: 'cover',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    cardTitle: {
        fontWeight: '600',
        color: '#25387c',
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    cardDescription: {
        color: '#555',
        textAlign: 'center',
    },
    h5: {
        flexGrow: '1 !important',
        marginBottom: '16px !important',
    },
    buttonContainer : {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 32,
    },
    promoButton: {
        padding: theme.spacing(1.5, 4),
    }
}));

const GrowthAgentAI = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const [calendlyUrl] = useState('https://calendly.com/opengrowth-support/30min');

    return (
        <>
            {/* ====== HEADER SECTION WITH BACKGROUND IMAGE ====== */}
            <Box className={classes.headerContainer}>
                <Box className={classes.textContainer}>
                    <Typography variant="subtitle1" className={styleClasses.h6banner}>
                        Combine the power of AI with human expertise with our
                    </Typography>
                    <Typography variant="h4" className={styleClasses.h4font}>
                        Growth AI Agent
                    </Typography>
                </Box>
            </Box>

            {/* ====== MAIN CONTENT (WHY HIRE) ====== */}
            <Box className={classes.container}>
                <Typography
                    variant="subtitle1"
                    className={styleClasses.subtitlefont}
                >
                    Accelerate scaling with Growth AI Agents. Leverage AI-driven solutions and fractional expertise
                    to accelerate growth with tailored, outcome-based models.
                </Typography><br />
                <Typography
                    variant="subtitle1"
                    className={styleClasses.subtitlefont}
                >
                    Our Growth AI Agents automate critical business operations such as research, lead generation,
                    customer support, and process optimization. Agentic AI is revolutionizing the workspace by
                    enabling seamless collaboration between AI and humans, delivering actionable insights and
                    streamlining tasks for smarter decision-making. This helps businesses scale without the
                    overhead of full-time hires..
                </Typography><br />
                <Typography
                    variant="subtitle1"
                    className={styleClasses.subtitlefont}
                >
                    Co-work seamlessly with AI, enhance productivity, and tap into a network of seasoned experts
                    for strategy and execution.
                </Typography>
                <Box className={classes.buttonContainer}>
                <PopupButton
                    url={calendlyUrl}
                    rootElement={document.getElementById('root') || undefined}
                    text="Book a Discovery Call"
                    className={`${styleClasses.individualButton} ${classes.promoButton}`}
                    variant="contained"
                />
                </Box>
            </Box>
        </>
    );
};

export default GrowthAgentAI;
