import React, { useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// Replace these with your real imports
import businesscourse from '../assets/businesscourse.png';
import BrowseAllCourses from './AllCourseCard';
import FAQSection from '../Experts/FaqSection';
import courses from './CourseData';
import useGlobalStyles from '../styles/CourseStyles';
// import Header from '../signup-login/Header';
// import Footer from '../signup-login/Footer';

const useStyles = makeStyles((theme) => ({
  // Your other card styles remain the same
  featureCard: {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s, box-shadow 0.3s',
    marginBottom: '0px',
  },
  subtitlefont: {
    paddingTop: '0rem',
  },
  featureCardContent: {
    padding: '0px'
  },
  featuresSection: {
    padding: 32,
    marginTop: 32,
    marginBottom: 32,
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
  },
  h5font: {
    backgroundColor: '#fff !important',
    padding: '2px 0px',
  },

  /* 
    "newFeatureCard" with a wave on the bottom 80%.
    - We use a pseudo-element ::before to draw a wavy shape
      from 20% down to 100% of the card.
    - That leaves the top 0-20% uncolored.
  */
  newFeatureCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    border: 'none',
    marginTop: 32,
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      justifyContent: 'center',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '200%',            // Extra wide to allow sliding horizontally
      height: '100%',
      background: '#f9bb02',    // Yellow wave color
      zIndex: 0,                // behind text

      /*
        This polygon covers from 20% down to 100% of the card,
        with some wiggly points between ~18%-23% to form a wave.
        That means the "wave" effectively takes ~80% of the card's height
        (20%-100%), leaving the top 0%-20% transparent.
       */
      clipPath: 'polygon(' +
        '0 40%,' +      // left edge, 20% from top
        '5% 23%,' +
        '10% 18%,' +
        '20% 22%,' +
        '30% 18%,' +
        '40% 22%,' +
        '50% 18%,' +
        '60% 22%,' +
        '70% 18%,' +
        '80% 22%,' +
        '90% 18%,' +
        '100% 40%,' +   // right edge, 20% from top
        '100% 100%,' +  // bottom-right corner
        '0 100%' +      // bottom-left corner
      ')',

      // Animate horizontally
      animation: '$waveMove 8s linear infinite',
    },
  },

  // The text sections are layered above the wave
  newFeatureSection: {
    position: 'relative',
    zIndex: 1,         // ensures text is above wave
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
    fontWeight: 'bold',
    fontSize: '4.5rem',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1.5rem',
    },
  },

  // The wave background slides from 0 to -50% horizontally
  '@keyframes waveMove': {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(-50%)',
    },
  },
}));

// Banner portion
const Banner = ({ onExploreNow }) => {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();
  const styleClasses = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={globalClasses.bannerContainer}>
      <Box className={`${globalClasses.bannerTextContainer} ${styleClasses.bannerTextContainer}`}>
        <Typography variant="h3" className={globalClasses.h3font}>
          Cultivate Professional Growth with Our
          Expert-led <span className={globalClasses.span}>Online Courses</span> for Businesses
        </Typography>
        <Typography variant="h6" className={`${globalClasses.h6banner} ${globalClasses.h6HeadingDesc}`}>
          On-demand courses for AI startup/expansion
          stage companies that want to overcome growth
          challenges and stand out in the market.
        </Typography>
        <Box className={globalClasses.filterChipsContainer}>
          <Button
            variant="contained"
            className={globalClasses.businessButton}
            style={{ margin: 8 }}
            onClick={onExploreNow}
            aria-label="Explore all courses"
          >
            Explore Now
          </Button>
          <Button
            variant="contained"
            component={Link}
            style={{ margin: 8 }}
            to="/growth-academy"
            className={globalClasses.individualButton}
          >
            All Courses
          </Button>
        </Box>
      </Box>
      <Box className={globalClasses.bannerImageContainer}>
        <img
          src={businesscourse}
          alt="Banner"
          className={globalClasses.bannerImage}
        />
      </Box>
    </Box>
  );
};

// Features + wavy card
const CourseFeatures = () => {
  useEffect(() => {
    const mainTitle = 'Online Courses for Business | OpenGrowth.AI ';
    const subtitle = 'OpenGrowth.AI offers premium online courses for business growth. Equip yourself with the strategies and tools needed to scale your startup effectively.';
    document.title = `${mainTitle} - ${subtitle}`;
  }, []);

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const features = [
    {
      title: 'Access to learning community:',
      description:
        'Communicate with your own team members and others taking the course in the future for added insights and continuous learning.',
    },
    {
      title: 'World-class scaffolding:',
      description:
        'An abundance of resources in the form of blog articles, videos and study materials for those interested in going the extra mile.',
    },
    {
      title: 'Live sessions by Experts:',
      description:
        'Gain insights directly from industry experts, for added guidance and help.',
    },
    {
      title: 'Life-long learning:',
      description:
        'Course delivery promotes and activates life-long learning habits.',
    },
  ];

  return (
    <Box className={classes.featuresSection}>
      <Typography variant="subtitle1" className={globalClasses.subtitlefont} gutterBottom>
        Save on talent development costs with tailored content and practical insights from over 50 leading experts
      </Typography>
      <Typography variant="h4" className={globalClasses.h4font}>
        Features
      </Typography>

      <Grid container direction="column" spacing={2}>
        {features.map((feature, index) => (
          <Grid item xs={12} key={index}>
            <Card className={`${globalClasses.featureCard} ${classes.featureCard}`}>
              <CardContent className={classes.featureCardContent}>
                <Typography
                  variant="h5"
                  className={`${globalClasses.h5font} ${classes.h5font}`}
                >
                  {feature.title}
                </Typography>
                <Typography variant="subtitle1" className={`${globalClasses.subtitlefont} ${classes.subtitlefont}`}>
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Animated wave from 20% to 100% (80% coverage) */}
      <Card className={classes.newFeatureCard}>
        <Box className={classes.newFeatureSection}>
          <Typography variant="h1" className={globalClasses.h1font}>
            5X
          </Typography>
          <Typography variant="subtitle2" className={globalClasses.subtitlefont}>
            Faster learning with online methods
          </Typography>
        </Box>
        <Box className={classes.newFeatureSection}>
          <Typography variant="h1" className={globalClasses.h1font}>
            40%
          </Typography>
          <Typography variant="subtitle2" className={globalClasses.subtitlefont}>
            Less time required for training
          </Typography>
        </Box>
        <Box className={classes.newFeatureSection}>
          <Typography variant="h1" className={globalClasses.h1font}>
            60%
          </Typography>
          <Typography variant="subtitle2" className={globalClasses.subtitlefont}>
            Improved retention with digital learning
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

// Main component
const BusinessCourse = () => {
  const browseAllCoursesRef = useRef(null);

  const handleExploreNow = () => {
    if (browseAllCoursesRef.current) {
      browseAllCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box style={{ padding: 8, paddingTop: 0 }}>
      {/* <Header /> */}
      <Banner onExploreNow={handleExploreNow} />
      <CourseFeatures />

      {/* Browse All Courses Section */}
      <Box ref={browseAllCoursesRef}>
        <BrowseAllCourses courses={courses} context="business" />
      </Box>
      {/* <Testimonials context="businesscourses" /> */}
      <FAQSection context="businessCourse" />
      {/* <Footer /> */}
    </Box>
  );
};

export default BusinessCourse;
