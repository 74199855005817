// src/components/LazyLoadComponent.js

import React, { useState, useEffect } from 'react';
import { CircularProgress, Box } from '@material-ui/core';

const LazyLoadComponent = ({ children, fallback = null }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = React.useRef();

  // Provide a small fallback if none is passed
  const defaultFallback = (
    <Box display="flex" justifyContent="center" alignItems="center" height={80}>
      <CircularProgress size={24} />
    </Box>
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0 // Load as soon as it's in the viewport
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref}>
      {isVisible ? (
        <React.Suspense fallback={fallback || defaultFallback}>
          {children}
        </React.Suspense>
      ) : (
        fallback || defaultFallback
      )}
    </div>
  );
};

export default LazyLoadComponent;
