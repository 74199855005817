// src/components/FAQSection.jsx

import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useGlobalStyles from '../styles/CourseStyles';
import { defaultFaqData, contextFaqData } from './FaqData'; // Import FAQ data

const useStyles = makeStyles((theme) => ({
  faqContainer: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(4),
    backgroundColor: '#fff',
    borderRadius: 12,
    marginTop: '5rem',
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    position: 'relative', // Ensure relative positioning so our fixed header behaves well
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#f9bb02',
    borderRadius: '4px',
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    color: '#555',
    textAlign: 'center',
  },
  accordion: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    borderRadius: 8,
    minHeight: 56,
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px',
    },
  },
  accordionDetails: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
    borderRadius: '0 0 8px 8px',
  },
  question: {
    color: '#000',
    fontFamily: 'MyCustomFont, sans-serif',
  },
  answer: {
    fontSize: '1.2rem',
  },
}));

const FAQSection = ({ context }) => { // Accept context as a prop
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine which FAQ data to use based on context
  const faqData = React.useMemo(() => {
    if (context && contextFaqData[context]) {
      return contextFaqData[context];
    }
    return defaultFaqData;
  }, [context]);

  // ========= Begin fixed header implementation =========
  // Create refs for the overall FAQ container and the header element.
  const faqRef = React.useRef(null);
  const headerRef = React.useRef(null);
  const [isHeaderFixed, setIsHeaderFixed] = React.useState(false);
  const [headerHeight, setHeaderHeight] = React.useState(0);

  // After the header mounts, record its height.
  React.useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  // Listen for scroll events and adjust the fixed state.
  React.useEffect(() => {
    const handleScroll = () => {
      if (faqRef.current && headerRef.current) {
        const faqRect = faqRef.current.getBoundingClientRect();
        /* 
          When the FAQ container’s top has scrolled past (or to) the header height,
          and its bottom is still below that header height, we fix the header.
          This means the header is fixed only while the FAQ section is “active.”
        */
        if (faqRect.top <= headerHeight && faqRect.bottom > headerHeight) {
          setIsHeaderFixed(true);
        } else {
          setIsHeaderFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Run an initial check in case the component is already in view.
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerHeight]);
  // ========= End fixed header implementation =========

  return (
    // Attach the ref to the FAQ container.
    <Box className={classes.faqContainer} ref={faqRef}>
      {/* Header with conditional fixed positioning */}
      <Typography
        ref={headerRef}
        variant="h4"
        className={`${styleClasses.h4font} ${classes.title}`}
        style={
          isHeaderFixed
            ? {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1100,
                backgroundColor: '#f9bb02', // Reapply background so it stays solid
              }
            : {}
        }
      >
        Frequently Asked Questions
      </Typography>
      {/* 
        When the header is fixed (and thus taken out of the normal document flow),
        add a spacer div to prevent a layout jump.
      */}
      {isHeaderFixed && <div style={{ height: headerHeight }} />}
      
      {/* FAQ Accordions */}
      {faqData.map((faq, index) => (
        <Accordion key={index} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            <Typography variant="h6" className={styleClasses.h6font}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQSection;
