// src/styles/globalStyles.js

import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';

const useGlobalStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'boldFont, sans-serif', // Apply the custom font globally
    },
    // Typography Styles
    h6font: {
        fontWeight: '500',
        color: 'black',
        borderRadius: 8,
        display: 'inline-block',
        padding: '2px 6px',
        fontSize: '23.5975px',
        fontFamily: 'boldFont, sans-serif',
        letterSpacing: '0.05rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
        },
    },
    h5font: {
        fontWeight: '700',
        color: 'black',
        borderRadius: 12,
        display: 'inline-block',
        padding: '0px 6px',
        letterSpacing: '0.08rem',
        fontSize: '27.3333px',
        lineHeight: '45px',
        fontFamily: 'boldFont, sans-serif',
        backgroundColor: '#f9bb02',
        // flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '33px',
        },
    },
    h5fontPara: {
        fontFamily: 'boldFont, sans-serif',
        letterSpacing: '0.05rem',
        fontSize: '33.5975px',
        lineHeight: '38px',
        textAlign: 'center', 
        fontWeight: '300',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
        },
    },
    h4font: {
        fontWeight: '700',
        fontSize: '45.6px',
        color: '#25387c',
        paddingBottom: '0.7rem',
        letterSpacing: '0.1rem',
        lineHeight: '50px',
        fontFamily: 'boldFont, sans-serif',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
            paddingBottom: 0,
            lineHeight: '40px',
            paddingBottom: '0.5rem',
        },
    },
    h5heading: {
        letterSpacing: '0.02rem',
        fontSize: '33.5975px',
        lineHeight: '38px',
        fontWeight: '500',
        color: '#25387c',
        marginBottom: 16,
        
        fontFamily: 'boldFont, sans-serif',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
    },
    h3font: {
        // fontWeight: 'bold',
        position: 'relative',
        zIndex: 1,
        width: '840px',
        color: '#000',
        marginBottom: '2rem',
        fontFamily: 'boldFont, sans-serif',
        letterSpacing: '0.05rem',
        lineHeight: '60px',
        // Responsive font size
        [theme.breakpoints.down('sm')]: {
            width:'auto',
            fontSize: '2rem',
            lineHeight: '55px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '55.1986px', //change 3 to 5
        },
    },
    h2font: {
        color: '#fff',
        fontWeight: '500',
        fontFamily: 'boldFont, sans-serif',
        letterSpacing: '0.05rem',
        fontSize: '62.5972px',
        alignItems: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            textAlign: 'left',
            fontSize: '45px',
            textAlign: 'center'
        },
    },
    h1heading: {
        color: '#fff',
        fontWeight: '500',
        fontFamily: 'boldFont, sans-serif',
        letterSpacing: '0.05rem',
        fontSize: '4.2rem',
        alignItems: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            textAlign: 'left',
            fontSize: '45px',
            textAlign: 'center'
        },
    },
    h1font: {
        color: '#25387c',
        fontWeight: '700',
        fontFamily: 'boldFont, sans-serif',
        letterSpacing: '0.05rem',
        fontSize: '85.5972px',
        alignItems: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
            fontSize: '1.5rem',
        },
    },
    h1banner: {
        color: '#25387c',
        fontWeight: '700',
        fontFamily: 'boldFont, sans-serif',
        letterSpacing: '0.05rem',
        fontSize: '52.5972px',
        alignItems: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
            fontSize: '1.5rem',
        },
    },
    span: {
        color: '#f9bb02', 
        fontWeight: '700',
        fontSize: '74.9087px',
        lineHeight: '90px',
        letterSpacing: '0.1rem',
        [theme.breakpoints.down('sm')]: {
            width:'auto',
            fontSize: '55px',
            lineHeight: '70px',
        },
    },
    subtitlefont: {
        fontFamily: 'myCustomFont, sans-serif',
        fontSize: '18.2px',
        lineHeight: '1.6',
        [theme.breakpoints.down('sm')]: {
            fontSize: '15.2px',
        },

    },
    subtitle2font: {
        fontFamily: 'myCustomFont, sans-serif',
        fontSize: '13.5px',
        lineHeight: '1.3',
    },
    h6HeadingDesc: {
        marginBottom: '2rem',
    },
    h6banner: {
        fontFamily: 'MyCustomFont, sans-serif',
        fontSize: '1.4rem',
        [theme.breakpoints.down('sm')]: {
            width:'90%',
            fontSize: '1rem',
            lineHeight: '30px',
        },
    },
    description: {
        fontFamily: 'MyCustomFont, sans-serif',
        fontSize: '1.25rem',
        [theme.breakpoints.down('sm')]: {
            width:'90%',
            fontSize: '1rem',
            lineHeight: '30px',
        },
    },
    body1font: {
        fontFamily: 'myCustomFont, sans-serif',
        fontSize: '16.5px',
        lineHeight: '1.5',
    },
    body2font: {
        fontFamily: 'MyCustomFont, sans-serif',
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontSize: '1rem',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
            
            fontSize: '0.875rem',
            
        },
    },

    // Button Styles
    businessCourseButton: {
        border: '4px solid #25387c',
        backgroundColor: 'white',
        color: '#25387c',
        borderRadius: '4px 0 0 4px',
        padding: '4px 24px',
        textTransform: 'none',
        fontSize: '1.2rem',
        fontWeight: '600',
        width: "50%",
        fontFamily: 'MyCustomFont, sans-serif',
        '&:hover': {
            backgroundColor: '#fff',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            padding: '4px 2px',
        },
    },
    individualCourseButton: {
        border: '4px solid #25387c',
        borderLeft: 'none', // Remove left border to attach to businessButton
        backgroundColor: '#25387c',
        color: 'white',
        borderRadius: '0 4px 4px 0',
        padding: '4px 24px',
        textTransform: 'none',
        fontSize: '1.2rem',
        width: "50%",
        fontWeight: '600',
        fontFamily: 'MyCustomFont, sans-serif',
        '&:hover': {
            backgroundColor: '#25387c',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            padding: '4px 2px',
        },
    },

    headerButtons: {
        gap: '0 !important',
    },
   
    businessButton: {
        backgroundColor: '#25387c',
        borderRadius: '50px',
        color: 'white',
        fontSize: '1.2rem',
        fontWeight: '600',
        padding: '4px 16px',
        fontFamily: 'MyCustomFont, sans-serif',
        '&:hover': {
            backgroundColor: '#061d70',
            transform: "translateY(-2px)",
            boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            padding: '6px 6px',
        },
    },
    individualButton: {
        backgroundColor: '#f9bb02',
        borderRadius: '50px',
        color: 'black',
        fontSize: '1.2rem',
        fontWeight: '600',
        padding: '4px 16px',
        border: 'none',
        fontFamily: 'MyCustomFont, sans-serif',
        '&:hover': {
            boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
            backgroundColor: "#e7be46",
            transform: "translateY(-2px)",
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        padding: '6px 6px',
        },
    },

    readMoreButton: {
        color: '#25387c',
        
        fontSize: '0.875rem',
        fontWeight: '500',
        fontFamily: 'MyCustomFont, sans-serif',
        '&:hover': {
            color: '#25387c',
            backgroundColor: '#f5f5f5',
            borderRadius: '20px',
            border: '2px solid #25387c',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
            padding: '6px 6px',
        },
    },
    // Box Styles
    bannerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start', // Align items to the top
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        height: 'auto',
        borderRadius: 8,
        // boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            paddingLeft: '0rem',
            paddingRight: '0rem',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            alignItems: 'center', 
        },
    },
    bannerTextContainer: {
        width: '55%', // Adjust as needed
        textAlign: 'left',
        padding: 32,
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    bannerImageContainer: {
        width: '42%', // Adjust as needed
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: '1.5rem', // Push the image down to align with h6
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 32,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '50%',
            marginTop: '1rem', // Adjust for tablet sizes
        },
    },
    bannerImage: {
        width: '100%',
        height: '100%', // Maintain aspect ratio
        borderRadius: 4,
        objectFit: 'cover',
        position: 'relative',
        top: '0px', // Adjust if necessary
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            height: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto'
        },
    },
    // Course Features Styles
    courseFeaturesContainer: {
        padding: 32,
        marginBottom: 32,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        marginTop: '5rem',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    courseFeaturesHeading: {
        marginBottom: 16,
    },
    courseFeaturesImage: {
        maxWidth: '100%',
        borderRadius: '12px',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block',
    },
    featureCard: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1rem',
        alignItems: 'flex-start',
        padding: '0px 16px 8px 16px',
        borderRadius: '8px',
        // boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        border: 'none',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 6px 8px 6px',
        },
    },
    courseFeatureImgBox: {
        marginTop: '3rem',
        marginBottom: '3rem',
        borderRadius: '30px',
        flexGrow: 1,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    courseFeatureImg: {
        position: 'relative',
        maxWidth: '100%',
        borderRadius: '16px',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block',
        
    },
    customArrow: {
        width: 24,
        height: 24,
        backgroundColor: '#25387c', // Blue background
        color: '#fff', // White ">" symbol
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8, // Space between arrow and title
        fontWeight: 'bold',
        fontSize: '1rem',
        flexShrink: 0, // Prevent shrinking on smaller screens
    },
    // Course Card Styles
    courseCard: {
        boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
        borderRadius: 8,
        height: '21em',
        display: 'flex',
        flexDirection: 'column',
        transition: '0.3s',
        '&:hover': {
            transform: "translateY(-3px)",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
            backgroundColor: '#0000000a',
        },
    },
    courseImage: {
        width: '100%',
        height: 220,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        backgroundColor: '#e0e0e0',
    },
    overlayText: {
        position: 'absolute',
        top: 10,
        left: 10,
        color: '#fff',
        fontWeight: 'bold',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius,
        marginRight: '5em',
        fontFamily: 'MyCustomFont, sans-serif',
    },
    categoryChip: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        backgroundColor: '#f9bb02',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: '0.75rem',
        padding: '4px 8px',
        height: 'auto',
        fontFamily: 'MyCustomFont, sans-serif',
    },
    courseContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '16px',
    },
    // New Information Card Styles
    newInfoCard: {
        padding: 32,
        paddingTop: '5rem',
        marginTop: '5rem',
        paddingBottom: '5rem',
        background: 'linear-gradient(to bottom,rgb(0, 0, 0), gainsboro)',
        borderRadius: 12,
        boxShadow: 'none',
        color: '#fff',
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingTop: '1.5rem',
            paddingBottom: '2rem',
        },
        
    },
    // Browse All Courses Section Styles
    browseCoursesCard: {
        padding: 32,
        paddingBottom: 64,
        marginTop: '5rem',
        boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
        borderRadius: "12px",
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    browseCoursesHeading: {
        textAlign: 'center',
    },
    filterChipsContainer: {

        marginBottom: '2.5rem',
        marginTop: '1rem',
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        gap: 16,
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        
    },
    filterChip: {
        marginRight: 8,
        fontSize: '1.12rem',
        fontWeight: '400',
        fontFamily: 'boldFont, sans-serif',
        letterSpacing: '0.02rem',
    },
    // Feature Card Styles
    featureCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        height: '100%',
    },
    mainGrid: {
        // marginTop: '0rem',
    },
    grid: {
        display: 'flex',
        flexDirection: 'column',
    },
    courseGridContainer: {
        marginTop: 12
    },
    // Additional Styles
    sliderWrapper: {
        "& .slick-list": {
            margin: "0 -8px",
        },
        "& .slick-slide": {
            padding: "16px 8px",
        },
    },
    // Navigation Button Styles (if any)
    navigationButton: {
        minWidth: "40px",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        padding: 0,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
        },
    },
}));

export default useGlobalStyles;
